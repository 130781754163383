@charset "UTF-8";
// ==========================================================================
// list
// ==========================================================================
@use "../../configs/" as *;


.c_newsListOne {
  @include mqDF() {
    padding: 22px 0;
  }
  padding: 32px 0;
  position: relative;
  &:first-child {
    @include mqDF() {
      padding: 0 0 22px 0;
    }
    padding: 0 0 32px 0;
  }
  a {
    display: block;
    height: 100%;
    @include transition(.5);
    width: 100%;
  }
  &::after {
    background: $color-gray-c;
    bottom: 0;
    content: "";
    height: 1px;
    left: 0;
    opacity: .8;
    position: absolute;
    width: 100%;
  }
  .m_newsListLine {
    background: $color-green;
    bottom: 0;
    height: 1px;
    left: 0;
    position: absolute;
    transition: all .4s cubic-bezier(.940, .000, .090, .940);
    width: 0;
    z-index: 2;
  }
  .m_newsListDL {
    dd {
      @include transition(.5);
      @include mqDF() {
        margin-top: 8px;
      }
      margin-top: 10px;
    }
  }
  &:hover {
    a {
      @include mqDF() {
        transform: translateX(0%);
      }
      transform: translateX(3%);
    }
    .m_newsListDL {
      dd {
        color: $color-green;
        @include mqDF() {
          color: $color-white;
        }
      }
    }
    .m_newsListLine {
      @include mqDF() {
        width: 0;
      }
      width: 100%;
    }
  }
}


// .c_projectList{
// }
