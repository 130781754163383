@charset "UTF-8";
// ==========================================================================
// button
// ==========================================================================
@use "../../configs/" as *;
.c_catNav {
  display: flex;
  gap: 0 40px;
  @include mqDF() {
    gap: 0 16px;
  }
}



.c_pagerNavWrap {
  display: flex;
  justify-content: center;
}
.c_pagerNav {
  display: flex;
  gap: 0 40px;
}
