@charset "UTF-8";
// ==========================================================================
// card
// ==========================================================================
@use "../../configs/" as *;

.c_memberOne {
  position: relative;
  .m_memberSNS {
    bottom: -1.3%;//44px;
    position: absolute;
    right: 5px;
    z-index: 2;
  }
  .m_memberProf {
    bottom: 2%;//44px;
    left: 12px;
    position: absolute;
    width: 78%;
    z-index: 2;
    dt {
      margin-bottom: 4px;
    }
    dd {
      line-height: 1.6;
    }
  }
}
.m_memberImage {
  border-radius: 12px;
  height: 80vh;//80vh;
  max-height: 720px;
  overflow: hidden;
  perspective: 1000px;
  transition: transform .2s ease-out;
  z-index: 1;
  &.leave,
  .enter {
    // transition: 2s transform cubic-bezier(0.550, 0.085, 0.680, 0.530);;
  }
}

.m_memberImageInner {
  height: 0;
  @include mqDF() {
    margin-top: 0;
    padding-top: 80vh;
  }
  padding-top: 80vh;
  position: relative;
  width: 100%;
  z-index: 1;
  // border-radius: 12px;
  // margin-bottom: -1px;
  video {
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
  }
  @media screen and (min-height: 1100px) {
  }
  @media screen and (min-height: 1200px) {
  }
  @media screen and (min-height: 1300px) {
    padding-top: 70vh;
  }
  @media screen and (min-height: 1500px) {
    padding-top: 65vh;
  }
  @media screen and (min-height: 1600px) {
    padding-top: 60vh;
  }
  @media screen and (min-height: 1700px) {
    padding-top: 55vh;
  }
  @media screen and (min-height: 1800px) {
    padding-top: 50vh;
  }
  @media screen and (min-height: 1900px) {
    padding-top: 45vh;
  }
  @media screen and (min-height: 2000px) {
    padding-top: 40vh;
  }
}
.c_projectCardList {
  display: flex;
  flex-wrap: wrap;
  gap: 80px 0;
  @include mqDF() {
    gap: 32px 0;
  }
  &.c_projectCardListAdd {
    @include mqDF() {
      margin-top: 32px;
    }
    margin-top: 80px;
  }
  // margin-left: -4%;
  .c_projectCard {
    @include mqDF() {
      margin-right: 0;
      min-width: 100%;
      width: 100%;
    }
    margin-right: 3%;
    min-width: 246px;
    width: 22%;
    &:nth-of-type(4n) {
      // margin-right: 0;
    }
  }
}

.c_projectCard {
  aspect-ratio: 264/528;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  a {
    display: block;
    height: 100%;
    width: 100%;
  }
  .m_projectCardImage {
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    .m_projectCardImageInner {
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
    &::after {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, .7) 57.81%, rgba(0, 0, 0, .9) 100%);
      content: "";
      height: 100%;
      @include transitionExpo;
      @include mqDF() {
        opacity: 1;
      }
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .c_projectCardInfo {
    bottom: 16px;
    @include mqDF() {
      bottom: 32px;
      transform: translateY(0%);
      visibility: visible;
    }
    left: 6%;
    position: absolute;
    transform: translateY(140%);
    visibility: hidden;
    .m_projectCardCaption {
      ///
    }
    .m_projectCardView {
      //
    }
  }
  .m_projectCardImageMotion {
    display: none;
  }

  &:hover {
    .m_projectCardImage {
      &::after {
        opacity: 1;
      }
    }
    .m_projectCardImageStatic {
      display: none;
    }
    .m_projectCardImageMotion {
      display: block;
    }
    .c_projectCardInfo {
      @include transitionExpo;
      transform: translateY(0);
      visibility: visible;
    }
  }
}
