@charset "UTF-8";
// ==========================================================================
// title
// ==========================================================================
@use "../../configs/" as *;

.m_pageTitle {
  font-size: 7.2rem;
  font-weight: bold;
  color: $color-white;
  letter-spacing: .04em;
  @include interFont;
  @include mqDF() {
    font-size: 4.8rem;
  }
}


.m_sectionTitle {
  font-size: 7.2rem;
  font-weight: bold;
  font-feature-settings: "halt" on;
  color: $color-white;
  letter-spacing: .04em;
  letter-spacing: .04em;
  @include interFont;
  @include mqDF() {
    font-size: 4.8rem;
  }
  .Note{
    font-size: 1.4rem;
    letter-spacing: 0.04em;
    span{
      color: $color-green;
    }
  }
}

.m_sectionTitleNote{
  display: block;
  margin-top: 2px;
  font-size: 1.4rem;
  letter-spacing: 0.04em;
  span{
    color: $color-green;
  }
  @include mqDF() {
    font-size: 1.2rem;
  }
}
