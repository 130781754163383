@charset "UTF-8";
// ==========================================================================
// AboutPage
// ==========================================================================
@use "../configs/" as *;

.aboutPage {
  &.En {
    @include interFont;

    .aboutRead {
      @include mqDF() {
        padding-bottom: 72px;
        padding-top: 80px;
      }
      padding-top: 160px;
    }
    .aboutCompany {
      @include mqDF() {
        padding-bottom: 30px;
        padding-top: 50px;
      }
      padding-top: 124px;
    }

    .aboutCompanyTitle {
      letter-spacing: .1rem;
    }
  }
  .l_secondaryContent {
    @include mqDF() {
      padding-bottom: 86px;
      padding-top: 111px;
    }
    padding-bottom: 128px;
    padding-top: 200px;
  }

  .l_contentInner {
    @media screen and (min-width: 2000px) {
      padding-left: 4%;
      padding-right: 4%;
    }
  }

  .aboutRead {
    background: #000;
    @include mqDF() {
      padding-bottom: 46px;
      // padding-top: 60px;
    }
    padding-bottom: 120px;
    position: relative;


    .aboutReadRoll {
      background: $color-black;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: scale(1, 1);
      transform-origin: center bottom;
      width: 100%;
      z-index: 10;
      &.BgDown {
        transform: scale(1, 0);
        transition: all .7s .1s cubic-bezier(.695, .045, .970, .140);
      }
    }


    .l_contentThickInner {
      @include mq(xxxl) {
        padding-left: 10%;
        padding-right: 10%;
      }
      @include mq(xxl) {
        padding-left: 14%;
        padding-right: 14%;
      }
      @include mqDF() {
        padding-left: 5%;
        padding-right: 5%;
      }
      padding-left: 16%;
      padding-right: 16%;
    }
    div {
      font-size: 2.6rem;
      @include fz-vw(26, 1440);
      @include mqDF() {
        @include fz-vw(16, 375);
        margin-bottom: 16px;
      }
      line-height: 1.6;
      margin-bottom: 40px;

      p {
        &.En {
          @include fz-vw(32, 1440);
          @include mqDF() {
            @include fz-vw(18, 375);
            // margin-bottom: 16px;
          }
          line-height: 1.52;
        }
      }
    }
    h2 {
      font-size: 7.185rem;
      @include fz-vw(71.85, 1440);
      @include mqDF() {
        @include fz-vw(32, 375);
        width: 100%;
      }
      letter-spacing: -.06em;
      line-height: 1.2;
      width: 110%;

      &.En {
        font-size: 9rem;
        @include fz-vw(80, 1440);
        @include mqDF() {
          @include fz-vw(30, 375);
        }
        letter-spacing: .1rem;
      }
    }
  }

  .aboutCompany {
    @include mqDF() {
      padding-top: 83px;
    }
    padding-top: 116px;
    .aboutCompanyTitle {
      font-size: 2.4rem;
      font-weight: 500;
      @include mqDF() {
        font-size: 1.8rem;
      }
      line-height: 1.6;
      text-align: center;
    }

    .aboutCompanyArea {
      @include mqDF() {
        margin-top: 36px;
      }
      margin-top: 46px;
      &.En {
        margin-top: 40px;
      }
      dt {
        font-size: 2.0rem;
        font-weight: 500;
        @include mqDF() {
          font-size: 1.6rem;
        }
        text-align: center;
      }
      .aboutCompanyAreaList {
        margin-top: 24px;
        &.En {
          margin-top: 34px;
          ul {
            align-items: stretch;
            li {
              align-items: center;
              display: flex;
              font-size: 1.8vw;
              @include mqDF() {
                font-size: 1.4rem;
                justify-content: center;
                text-align: center;
                width: 50%;
              }
              text-align: center;
              @media screen and (min-width: 1600px) {
                font-size: 2.6rem;
              }
              &:nth-child(3) {
                width: 44%;
              }
            }
          }
        }
        hr {
          color: rgba($color-white, $alpha: .6);
          margin: 20px 0 0 0;;
        }
        ul {
          display: flex;
          gap: 0 3%;
          @include mqDF() {
            align-items: center;
            flex-wrap: wrap;
            gap: 0 0;
            &:last-child {
              margin-top: 20px;
            }
          }
          justify-content: center;
          li {
            color: $color-green;
            font-size: 1.6vw;//2.4rem;
            font-weight: 500;
            @include mqDF() {
              font-size: 1.8rem;
              text-align: center;
              width: 50%;
            }
            padding-right: 3%;
            position: relative;
            @media screen and (min-width: 1600px) {
              font-size: 2.6rem;
            }
            &:last-child {
              padding-right: 0;
              &::after {
                display: none;
              }
            }
            &::after {
              background: rgba($color-white, $alpha: .6);
              content: "";
              display: block;
              height: 120%;
              @include mqDF() {
                height: 110%;
                top: -5%;
              }
              position: absolute;
              right: 0;
              top: -10%;
              width: 1px;
            }
          }
        }
      }
    }

    .aboutCompanyTable {
      display: flex;
      @include mqDF() {
        display: block;
        margin-top: 94px;
      }
      margin-top: 156px;
      &.En {
        margin-top: 84px;
        .aboutCompanyTableBody {
          @include mqDF() {
            margin-top: 14px;
          }
          dd {
            line-height: 1.9;
          }
        }
      }
      .aboutCompanyTableTitle {
        @include mqDF() {
          width: 100%;
        }
        margin-top: -1.8%;
        width: 44%;
      }
      .aboutCompanyTableBody {
        @include mqDF() {
          width: 100%;
        }
        width: 56%;
        dl {
          display: flex;
          @include mqDF() {
            display: block;
          }
          justify-content: space-between;
          &:first-child {
            dt {
              padding-top: 0;
            }
            dd {
              padding-top: 0;
            }
          }
          &:last-child {
            dt {
              border-bottom: none;
              padding-bottom: 0;
            }
            dd {
              border-bottom: none;
              padding-bottom: 0;
            }
          }
          dt {
            border-bottom: solid 1px rgba($color-white, $alpha: .6);
            font-size: 1.8rem;
            font-weight: 500;
            @include mqDF() {
              border-bottom: none;
              font-size: 1.2rem;
              padding: 16px 0 0 0;
              width: 100%;
            }
            padding: 28px 0;
            width: 23%;
          }
          dd {
            border-bottom: solid 1px rgba($color-white, $alpha: .6);
            font-size: 1.8rem;
            font-weight: 500;
            @include mqDF() {
              font-size: 1.6rem;
              padding: 0 0 16px 0;
              width: 100%;
            }
            padding: 28px 0;
            width: 70%;
          }
        }
      }
    }
  }
}
