@charset "UTF-8";
// ==========================================================================
// button
// ==========================================================================
@use "../../configs/" as *;

.m_linkArrow {
  display: block;
  @include transition(.5);
  &::after {
    background: $color-green;
    bottom: -1px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    transform: scale(0, 1);
    transform-origin: left center;
    // @include transitionExpo(1);
    transition: all .4s cubic-bezier(.940, .000, .090, .940);
    width: 100%;
  }
  &.Large {
    &::after {
      transform: scale(0, 1);
      transition: all .6s cubic-bezier(.940, .000, .260, .740);
    }
    span {
      background-size: 44px;
      @include mqDF() {
        background-size: 25px;
        padding-right: 36px;
      }
      padding-right: 66px;
    }

    &:hover {
      padding-left: 3%;
    }
  }
  span {
    background: url("../images/icon_link_arrow.svg") no-repeat 100% center;
    background-size: 25px;
    padding-right: 46px;
  }
  &:hover {
    padding-left: 4%;
    &::after {
      transform: scale(1, 1);
    }
  }
}

.m_viewLink {
  display: block;
  height: 28px;
  @include transition(.2);
  position: relative;

  &.Disabled {
    opacity: .2;
    pointer-events: none;
  }
  a,
  button {
    background: url("../images/icon_view_arrow.svg") no-repeat 100% center;
    background-size: 9px;
    display: block;
    font-size: 1.6rem;
    @include interFont;
    @include mqDF() {
      font-size: 1.4rem;
    }
    line-height: 28px;
  }
  button {
    width: 100%;
  }
  span {
    padding-left: 1rem;
  }
  &::after {
    background: $color-gray-c;
    bottom: -1px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  &::before {
    background: $color-green;
    bottom: -1px;
    content: "";
    height: 1px;
    @include transitionExpo(.5);
    left: 0;
    position: absolute;
    transform: scale(0, 1);
    transform-origin: left center;
    // transition: all .4s cubic-bezier(.940, .000, .090, .940);
    width: 100%;
    z-index: 2;
  }

  &:hover {
    color: $color-green;
    @include mqDF() {
      color: $color-white;
      padding-left: 0;
    }
    padding-left: 1%;
    a,
    button {
      background: url("../images/icon_view_arrow_g.svg") no-repeat 100% center;
      background-size: 9px;
      @include mqDF() {
        background: url("../images/icon_view_arrow.svg") no-repeat 100% center;
        background-size: 9px;
      }
    }
    &::before {
      @include mqDF() {
        transform: scale(0, 1);
      }
      transform: scale(1, 1);
    }
  }
}


.m_viewLinkLine {
  display: block;
  height: 28px;
  @include transition(.2);
  position: relative;

  &.Disabled {
    opacity: .2;
    pointer-events: none;
  }
  a,
  button {
    display: block;
    font-size: 1.6rem;
    @include interFont;
    @include mqDF() {
      font-size: 1.4rem;
    }
    line-height: 28px;
  }
  button {
    width: 100%;
  }
  span {
    padding-left: 1rem;
  }
  &::after {
    background: $color-gray-c;
    bottom: -1px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  &::before {
    background: $color-green;
    bottom: -1px;
    content: "";
    height: 1px;
    @include transitionExpo(.5);
    left: 0;
    position: absolute;
    transform: scale(0, 1);
    transform-origin: left center;
    // transition: all .4s cubic-bezier(.940, .000, .090, .940);
    width: 100%;
    z-index: 2;
  }

  &:hover {
    color: $color-green;
    @include mqDF() {
      color: $color-white;
      // padding-left: 0;
    }
    // padding-left: 1%;
    a,
    button {
      @include mqDF() {
      }
    }
    &::before {
      @include mqDF() {
        transform: scale(0, 1);
      }
      transform: scale(1, 1);
    }
  }
}

.m_backLink {
  display: block;
  height: 28px;
  @include transition(.2);
  position: relative;
  button {
    background-size: 9px;
    display: block;
    font-size: 1.6rem;
    @include interFont;
    @include mqDF() {
      font-size: 1.4rem;
    }
    line-height: 28px;
  }
  span {
    display: inline-block;
    position: relative;

    &::after {
      background: $color-gray-c;
      bottom: 2px;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
    &::before {
      background: $color-green;
      bottom: 2px;
      content: "";
      height: 1px;
      @include transitionExpo(.5);
      left: 0;
      position: absolute;
      transform: scale(0, 1);
      transform-origin: left center;
      // transition: all .4s cubic-bezier(.940, .000, .090, .940);
      width: 100%;
      z-index: 2;
    }
  }
  &:hover {
    color: $color-green;
    @include mqDF() {
      color: $color-white;
      padding-left: 0;
    }
    a,
    button {
      @include mqDF() {
      }
    }
    span {
      &::before {
        @include mqDF() {
          transform: scale(0, 1);
        }
        transform: scale(1, 1);
      }
    }
  }
}

.m_wantedButton {
  background: $color-black;
  border-radius: 72px;
  height: 72px;
  @include transitionExpo(.4);
  @include mqDF() {
    height: 47px;
  }//100%;
  width: 337px;

  &:hover {
    background: $color-white;
    @include mqDF() {
      background: $color-black;
    }
    a {
      &::after {
        border-left: 13px solid #000;
        @include mqDF() {
          border-left: 8px solid #fff;
        }
      }
    }

    .m_wantedButtonInner {
      background: url("../images/wantedly_logo_hover.svg") no-repeat 100% center;
      @include mqDF() {
        background: url("../images/wantedly_logo.svg") no-repeat 100% center;
        background-size: 100%;
        margin-left: 0;
      }
    }
  }
  a {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
    &::after {
      border-bottom: 8px solid transparent;
      border-left: 13px solid #fff;
      border-right: 0;
      border-style: solid;
      border-top: 8px solid transparent;
      content: "";
      height: 0;
      @include mqDF() {
        border-bottom: 5px solid transparent;
        border-left: 8px solid #fff;
        border-top: 5px solid transparent;
        top: calc(50% - 4px);
      }
      position: absolute;
      right: 10%;
      top: calc(50% - 6px);
      width: 0;
    }
  }
  .m_wantedButtonInner {
    background: url("../images/wantedly_logo.svg") no-repeat 100% center;
    height: 100%;
    @include mqDF() {
      background-size: 100%;
      margin-left: 0;
      width: 59%;
    }
    margin-left: -30px;
    width: 61%;
  }
}

.m_catNavItem {
  color: $color-gray-c;
  font-size: 2.0rem;
  @include interFont;
  @include mqDF() {
    font-size: 1.2rem;
  }
  letter-spacing: .04em;
  &.Current {
    color: $color-green;
  }
  a {
    display: block;
    @include underHover($color-green, 0px);
    position: relative;
  }
}

.m_pagerItem {
  color: $color-gray-c;
  font-size: 2.0rem;
  @include interFont;
  @include mqDF() {
    font-size: 1.6rem;
  }
  letter-spacing: .04em;
  &.Current {
    color: $color-green;
    a {
      &::after {
        background: $color-green;
        bottom: 0; //20%;//$pos + px;
        content: "";
        height: 1px;
        @include mqDF() {
          height: 1px;
        }
        left: 0;
        position: absolute;
        transform: scale(1, 1);
        width: 100%;
      }
    }
  }
  a {
    display: block;
    @include underHover($color-green, 0px);
    position: relative;
  }
}

.page-numbers {
  color: $color-gray-c;
  display: block;
  font-size: 2.0rem;
  @include interFont;
  @include mqDF() {
    font-size: 1.6rem;
  }
  &:not(.dots) {
    @include underHover($color-green, 0px);
    letter-spacing: .04em;
    position: relative;
  }
  &.current {
    color: $color-green;
    &::after {
      background: $color-green;
      bottom: 0; //20%;//$pos + px;
      content: "";
      height: 1px;
      @include mqDF() {
        height: 1px;
      }
      left: 0;
      position: absolute;
      transform: scale(1, 1);
      width: 100%;
    }
  }
}
