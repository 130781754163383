@charset "UTF-8";
// ==========================================================================
// list
// ==========================================================================
@use "../../configs/" as *;

.m_newsListDL {
  dt {
    color: $color-green;
    font-size: 1.6rem;
    font-weight: 500;
    @include interFont;
    @include mqDF() {
      font-size: 1.2rem;
    }
    letter-spacing: .04em;
    time {
      color: $color-gray-c;
      @include mqDF() {
        padding-left: 2rem;
      }
      padding-left: 1rem;
    }
  }
  dd {
    color: $color-white;
    font-size: 1.8rem;
    @include mqDF() {
      font-size: 1.4rem;
      letter-spacing: .04em;
    }
    letter-spacing: .04em;
    line-height: 1.6;
  }
}
