@charset "UTF-8";
// ==========================================================================
// TopPage
// ==========================================================================
@use "../configs/" as *;

.topPage {
  // overflow: hidden;

  &.En {
    @include interFont;
  }
}

.topKV {
  height: 100vh;
  @include mqDF() {
    height: 100vh; /* CSS変数をサポートしていないブラウザ用のフォールバック */
    height: calc(var(--vh, 1vh) * 100);
  }
  left: 0;
  opacity: 0;
  overflow: hidden;
  position: sticky;
  top: 0;
  transition: all 3s cubic-bezier(.190, 1.000, .220, 1.000);
  width: 100%;
  &.View {
    opacity: 1;
    .topKVInner {
      transform: scale(1);
    }
  }
  .topKVInner {
    height: 100vh;
    @include mqDF() {
      height: 100vh; /* CSS変数をサポートしていないブラウザ用のフォールバック */
      height: calc(var(--vh, 1vh) * 100);
    }
    transform: scale(2);
    transition: all 3s cubic-bezier(.190, 1.000, .220, 1.000);
    width: 100%;
  }
  #KV {
    height: 100%;
    width: 100%;
    canvas {
      margin: 0 auto;
    }
  }
}
body {
  overflow-x: hidden;
}

.topRead {
  @include mqDF() {
    padding-bottom: 80px;
    padding-top: 60px;
  }
  padding-bottom: 240px;
  padding-top: 160px;
  position: relative;

  .topReadRoll {
    background: $color-black;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transform: scale(1, 1);
    transform-origin: center bottom;
    width: 100%;
    z-index: 10;
    &.BgDown {
      transform: scale(1, 0);
      transition: all .7s .1s cubic-bezier(.695, .045, .970, .140);
    }
  }


  .l_contentThickInner {
    @include mq(xxxl) {
      padding-left: 10%;
      padding-right: 10%;
    }
    @include mq(xxl) {
      padding-left: 14%;
      padding-right: 14%;
    }
    @include mqDF() {
      padding-left: 5%;
      padding-right: 5%;
    }
    padding-left: 16%;
    padding-right: 16%;
  }
  div {
    font-size: 2.6rem;
    @include fz-vw(26, 1440);
    @include mqDF() {
      @include fz-vw(16, 375);
      margin-bottom: 16px;
    }
    line-height: 1.6;
    margin-bottom: 40px;

    p {
      &.En {
        @include fz-vw(32, 1440);
        @include mqDF() {
          @include fz-vw(18, 375);
          // margin-bottom: 16px;
        }
        line-height: 1.52;
      }
    }
  }
  h2 {
    font-size: 7.185rem;
    @include fz-vw(71.85, 1440);
    @include mqDF() {
      @include fz-vw(32, 375);
      width: 100%;
    }
    letter-spacing: -.06em;
    line-height: 1.2;
    width: 110%;

    &.En {
      font-size: 9rem;
      @include fz-vw(90, 1440);
      @include mqDF() {
        @include fz-vw(32, 375);
      }
      letter-spacing: .1rem;
    }
  }
}

.fullPageScroll {
  height: 100vh;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  scroll-snap-type: y mandatory;
  width: 100%;
}

.topMemberMemberTickerOuter {
  display: none;
  @include mqDF() {
    display: block;
    padding: 10vh 0 0 0 ;
    position: relative;
  }
  overflow: hidden;
}


.topMemberTicker {
  height: 300px;
  @include mqDF() {
    display: none;
  }
  left: 0;
  overflow: hidden;
  position: absolute;
  top: calc(50% - 150px);
  width: 100%;
  .topMemberTickerInner {
    transition: 1s all cubic-bezier(.215, .610, .355, 1.000);
    transition-delay: .4s;//800%;
    // display: flex;
    width: 400%;
  }
  .topMemberTickerOne {
    align-items: center;
    display: flex;
    height: 300px;
    opacity: 0;
    transition: .3s opacity cubic-bezier(.215, .610, .355, 1.000);
    transition-delay: .4s;//400%;
    width: 100%;
    &.Act {
      opacity: 1;
      transition: .8s opacity cubic-bezier(.215, .610, .355, 1.000);
      transition-delay: .6s;
    }
    .topMemberTickerOneInner {
      max-width: 2900px;///200%;
      width: 100%;
    }
  }
}
.topMemberMemberTicker {
  @include mqDF() {
    align-items: center;
    display: flex;
    width: 800%;
  }
  .topMemberTickerOneInner {
    @include mqDF() {
      width: 400%;
    }
  }
}
.topMember {
  overflow: hidden;
  position: relative;
  //
  // .scrollmagic-pin-spacer{
  //   @include mqDF(){
  //     // padding-top: 240vh !important;
  //     // padding-top: calc(var(--vh, 1vh) * 240) !important;
  //   }
  // }
  #pinMember {
    transform: translate3D(0,0,0);
  }
  #pinMemberWrap {
    height: 100%;
  }
  .topMemberInner {
    align-items: center;
    display: flex;
    height: 100vh;
    @include mqDF() {
      min-height: 100vh; /* Fallback */
      min-height: calc(var(--vh, 1vh) * 100);
    }
    justify-content: center;
    opacity: 0;
    position: relative;
    transform: translate3D(0,0,0);
    transition: .8s all cubic-bezier(.215, .610, .355, 1.000);
    visibility: hidden;
    width: 100%;
    &.View {
      opacity: 1;
      transform: translate3D(0,0,0);
      visibility: visible;
    }
  }
  .topMemberImage {
    height: 80vh;
    @include mqDF() {
      height: 80vh;
      max-width: 100%;
      min-width: 95%;
      width: 95%;
    }//23%;
    left: 50%;
    max-height: 720px;
    max-width: 340px;
    min-width: 300px;
    perspective: 1000px;
    position: absolute;
    top: 10vh;
    transform: translateX(-50%);
    width: 40vh;

    @media screen and (min-height: 1100px) {
      top: 18vh;
    }
    @media screen and (min-height: 1200px) {
      top: 20vh;
    }
    @media screen and (min-height: 1300px) {
      top: 24vh;
    }
    @media screen and (min-height: 1500px) {
      top: 26vh;
    }
    @media screen and (min-height: 1600px) {
      top: 27vh;
    }
    @media screen and (min-height: 1700px) {
      top: 29vh;
    }
    @media screen and (min-height: 1800px) {
      top: 30vh;
    }
    @media screen and (min-height: 1900px) {
      top: 31vh;
    }
    @media screen and (min-height: 2000px) {
      top: 32vh;
    }
    .m_memberImageWrap {
      // transition: 0.8s all  cubic-bezier(1.000, 0.000, 0.000, 1.000);
      transition: .8s all cubic-bezier(.375, .110, .145, 1.005);
    }
  }
  .topMemberBox {
    height: 80vh;
    @include mqDF() {
      height: 80vh;
      margin-top: -3vh;
      max-width: 100%;
      min-width: 95%;
      width: 90%;
    }//700px;//25%;//720px;
    margin-top: -5vh;
    max-height: 720px;
    max-width: 340px;
    min-width: 300px;
    overflow: hidden;
    position: relative;
    width: 40vh;
    // perspective:3000px;
    // transition: transform 0.2s ease-out;
    @media screen and (min-height: 950px) {
      margin-top: -7vh;
    }
    @media screen and (min-height: 980px) {
      margin-top: -11vh;
    }
    @media screen and (min-height: 1000px) {
      margin-top: -16vh;
    }
    @media screen and (min-height: 1100px) {
      margin-top: -5vh;
    }
    @media screen and (min-height: 1150px) {
      margin-top: -5vh;
    }
    @media screen and (min-height: 1200px) {
      margin-top: -5vh;
    }
    @media screen and (min-height: 1300px) {
      margin-top: -4vh;
    }
    @media screen and (min-height: 1500px) {
      margin-top: -4vh;
    }
    @media screen and (min-height: 1600px) {
      margin-top: -4vh;
    }
    @media screen and (min-height: 1700px) {
      margin-top: -3vh;
    }
    @media screen and (min-height: 1800px) {
      margin-top: -3vh;
    }
    @media screen and (min-height: 1900px) {
      margin-top: -3vh;
    }
    @media screen and (min-height: 2000px) {
      margin-top: -3vh;
    }
    .topMemberBoxInner {
      @include mqDF() {
        left: 2%;
        width: 93.5%;
      }
      left: 4%;
      position: absolute;
      top: 0;
      // transition: 0.8s all  cubic-bezier(1.000, 0.000, 0.000, 1.000);
      transition: .8s all cubic-bezier(.375, .110, .145, 1.005);
      width: 91%;
    }
    .topMemberBoxInnerWrap {
      transition: transform .2s ease-out;
    }
    .c_memberOne {
      height: 80vh;
      @include mqDF() {
        height: 80vh;
        margin: 0 auto;
        width: auto;
      }
      max-height: 720px;
      width: 100%;
    }
  }
}

.topWhat {
  @include mqDF() {
    padding-bottom: 50px;
    padding-top: 45px;
  }
  padding-bottom: 240px;
  padding-top: 140px;
  h2 {
    @include mqDF() {
      padding-bottom: 18px;
      padding-top: 45px;
      width: 100%;
    }
    padding-bottom: 38px;
    padding-top: 100px;
    width: 90%;
  }
  p {
    border-top: solid 1px #999;
    font-size: 2.4rem;
    @include mqDF() {
      font-size: 1.4rem;
      line-height: 1.8;
      padding-top: 12px;
    }
    padding-top: 36px;
    text-align: justify;
    span {
      color: $color-gray-a;
    }

    &.En {
      text-align: left;
    }
  }
  .topWhatView {
    @include mqDF() {
      display: none;
    }
    margin-top: 40px;
    width: 200px;
  }
}


.topBusiness {
  width: 100%;
  .topBusinessInner {
    display: flex;
    @include mqDF() {
      display: block;
      opacity: 1;
      padding-bottom: 120px;
      padding-left: 5.3%;
      padding-right: 5.3%;
      padding-top: 60px;
    }
    opacity: 0;
    position: relative;
    transition: .8s all cubic-bezier(.215, .610, .355, 1.000);
    &.View {
      opacity: 1;
    }
    .topBusinessLine {
      background: rgba($color: #fff, $alpha: .2);//$color-white;
      content: "";
      height: 80vh;
      @include transitionExpo;
      @include mqDF() {
        display: none;
      }
      left: 42.6%;
      position: absolute;
      top: 20vh;
      width: 1px;
      &.Community {
        top: 0;
        // bottom: 20vh;
        // transition: all 1s;
      }

      span {
        background: #fff;
        display: block;
        height: calc(80vh / 5);
        left: 0;
        position: absolute;
        top: 0;
        // transition: all 1s cubic-bezier(1.000, 0.000, 0.000, 1.000);
        transition: .8s all cubic-bezier(.215, .610, .355, 1.000);//red;
        width: 1px;
      }
    }
    .topBusinessTitle {
      align-items: center;
      display: flex;
      height: 100vh;
      @include mqDF() {
        border-bottom: solid 1px $color-gray-d;
        height: 100%;
        justify-content: flex-start;
        padding-bottom: 20px;
        width: 100%;
      }
      justify-content: center;
      width: 42.6%;
      .topBusinessTitleLogo {
        display: none;
        @include mqDF() {
          margin-left: 0;
          width: 50%;
        }
        margin-left: -10%;
        text-align: center;
        width: 52%;
        &.View {
          display: block;
        }
        &.Community {
          margin-left: 10%;
          width: 74%;
        }
      }
      &.Community {
        @include mqDF() {
          padding-bottom: 10px;
        }
        margin-top: 120px;
        .topBusinessTitleLogo {
          @include mqDF() {
            display: block;
            width: 70%;
          }
        }
      }
    }

    .topBusinessBody {
      height: 100vh;
      @include mqDF() {
        height: 100%;
        width: 100%;
      }
      overflow: hidden;
      width: 56.4%;
      .topBusinessBodyInner {
        height: 400vh;
        @include mqDF() {
          height: 100%;
        }
        transition: .5s all cubic-bezier(1.000, .000, .000, 1.000);
        width: 100%;
      }
      .section {
        align-items: center;
        display: flex;
        height: 100vh;
        @include mqDF() {
          height: 100%;
          &::after {
            display: none;
          }
        }
        justify-content: center;
        position: relative;
        .sectionInner {
          box-sizing: border-box;
        }
      }

      .topBusinessA, {
        font-size: 2.4rem;
        @include mqDF() {
          font-size: 1.4rem;
          padding-top: 14px;
          .sectionInner {
            padding: 0;
          }
        }
        &::after {
          height: 80%;
          left: 0;
          top: 20%;
        }
        .sectionInner {
          box-sizing: border-box;
          padding: 0 20% 0 13%;
        }
      }
      .topBusinessB {
        @include mqDF() {
          padding-top: 40px;
          .sectionInner {
            margin-left: 0;
          }
        }
        .sectionInner {
          box-sizing: border-box;
          padding: 0;
          // padding: 0 20% 0 13%;
          // margin-left: -11.5%;
        }
      }
      .topBusinessC {
        @include mqDF() {
          padding-top: 64px;
          .sectionInner {
            margin-left: 0;
          }
          p {
            font-size: 1.2rem;
            margin-top: 16px;
          }
        }
        .sectionInner {
          box-sizing: border-box;
          padding: 0;
          // margin-left: -11.5%;
        }
        p {
          font-size: 2.4rem;
          letter-spacing: .1rem;
          line-height: 1.8;
          margin-top: 33px;
          text-align: center;
        }
      }
      .topBusinessD {
        @include mqDF() {
          padding-top: 68px;
          .sectionInner {
            box-sizing: border-box;
            margin-left: 0;
            padding: 0;
            width: 100%;
          }
          .topBusinessCountBox {
            &:first-child {
              margin-bottom: 36px;
            }
            &:last-child {
            }
          }
          .topBusinessCount {
            // width: auto;
            text-align: center;
            dt {
              font-size: 1.2rem;
              margin-bottom: 8px;
            }
            dd {
              div {
                // padding-right:4rem;
                font-size: 6.4rem;
                @include interFont;
              }
              span,
              p {
                bottom: 1rem;
                // display: block;
                font-size: 2rem;
              }
              &.En {
                span {
                  bottom: 1rem;
                  font-size: 2rem;
                  text-align: right;
                }
                b {
                  font-size: 6.4rem;
                }
              }
            }
          }
        }
        .sectionInner {
          box-sizing: border-box;
          width: 100%;
        }
        .topBusinessCountBox {
          display: flex;
          justify-content: center;
          &:first-child {
            margin-bottom: 60px;
          }
          &:last-child {
            @include mqDF(lg) {
              padding: 0 0;
            }
            @include mqDF() {
              margin-left: -6%;
              padding: 0 10%;
            }
            justify-content: center;
            margin-top: 56px;//space-between;
            padding: 0 10%;
            &.En {
              padding: 0 5%;
            }
          }
        }
        .topBusinessCount {
          display: flex;
          flex-wrap: wrap;
          @include mqDF(xl) {
            width: 50%;
          }
          @include mqDF() {
            width: 50%;
          }
          justify-content: center;
          text-align: center;
          width: 40%;
          dt {
            font-size: 2.0rem;
            margin-bottom: 18px;
            width: 100%;
          }
          dd {
            color: $color-green;
            line-height: 1;
            position: relative;
            // display: flex;
            // justify-content: flex-end;
            width: 100%;
            div {
              display: inline-block;
              // padding-right: 6.5rem;
              font-size: 9.6rem;
              font-weight: bold;
              @include interFont;
              // letter-spacing: -0.02rem;
              letter-spacing: -.52rem;
            }
            span,
            p {
              // position: absolute;
              // right: 0;
              // bottom: 1.6rem;
              display: inline-block;
              font-size: 3.2rem;
              font-weight: bold;
              line-height: 1;
            }

            &.En {
              span {
                font-size: 3.2rem;
                text-align: right;
              }
              b {
                font-size: 9.6rem;
              }
            }
          }
        }
      }
      .topBusinessE {
        font-size: 2.4rem;
        @include mqDF() {
          font-size: 1.4rem;
          padding-top: 16px;
          .sectionInner {
            padding: 0;
          }
        }
        &::after {
          bottom: 20%;
          height: 80%;
          left: 0;
          top: unset;
        }
        .sectionInner {
          box-sizing: border-box;
          padding: 0 20% 0 13%;
        }
      }

      .sectionInnerTitle {
        font-size: 2.4rem;
        font-weight: 500;
        @include mqDF() {
          font-size: 1.4rem;
          margin-bottom: 20px;
        }
        letter-spacing: .1em;
        margin-bottom: 40px;
        text-align: center;
        span {
          position: relative;
          &::after {
            background: rgba($color: #fff, $alpha: .4);
            content: "";
            display: block;
            height: 1px;
            position: absolute;
            right: -40px;
            top: 50%;
            width: 32px;
          }
          &::before {
            background: rgba($color: #fff, $alpha: .4);
            content: "";
            display: block;
            height: 1px;
            left: -40px;
            position: absolute;
            top: 50%;
            width: 32px;
          }
        }
      }
    }
  }
}


.topProject {
  .topProjectInner {
    // background: red;
    display: flex;
    @include mqDF() {
      display: block;
      padding-bottom: 0;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 80px;
    }
    padding-bottom: 150px;
    padding-left: 8.33%;
    padding-right: 8.33%;
    padding-top: 150px;
  }
  .topProjectInnerBottom {
    @include mqDF() {
      display: block;
      padding-bottom: 80px;
    }
  }
  .topProjectInfo {
    display: flex;
    flex-flow: column;
    @include mqDF() {
      width: 50%;
    }
    justify-content: space-between;
    width: 50%;
    .topProjectMovieTitle {
      @include mqDF() {
        display: none;
      }
      dt {
        color: $color-green;
        font-size: 1.6rem;
        font-style: normal;
        font-weight: 500;
        @include interFont;
        letter-spacing: .04em;
        line-height: 150%;
        text-transform: uppercase;
      }
      dd {
        color: #fff;
        font-size: 4.0rem;
        font-style: normal;
        font-weight: 700;
        @include interFont;
        letter-spacing: .04em;
        line-height: 150%;
      }
    }
    .topProjectMovieView {
      @include mqDF() {
        display: none;
      }
      margin-top: 40px;
      width: 200px;
    }
  }
  .topProjectMovieALL {
    color: #fff;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 500;
    @include interFont;
    @include mqDF() {
      margin-top: 32px;
    }
    letter-spacing: .04em;
    line-height: 150%;
    .m_viewLinkLine {
      @include mqDF() {
        width: 100%;
      }
      width: 135px;
    }
  }
  .topProjectMovies {
    @include mqDF() {
      padding-top: 0;
      width: 100%;
    }
    padding-top: 49%;
    position: relative;
    width: 50%;

    .c_projectCard {
      // height: 65vh;
      @include mqDF() {
        height: 100%;
        left: unset;
        margin-top: 32px;
        position: relative;
        top: unset;
        width: 100%;
        &.Hide {
          opacity: 1;
          transform: scale(1) rotate(0deg);
          z-index: 1;
        }
        &.Stay {
          opacity: 1;
          transform: scale(1) rotate(0deg);
          z-index: 1;
        }
        &.Gone {
          opacity: 1;
          transform: scale(1) rotate(0deg);
          z-index: 1;
        }
      }
      @include transitionExpo(1);
      left: 25%;
      margin: 0 auto;
      padding-top: 100%;
      position: absolute;
      top: 0;
      // width: 22vw;
      z-index: 3;
      &.Hide {
        opacity: 0;
        pointer-events: none;
        transform: scale(.5) rotate(-46deg);
        z-index: 1;
      }
      &.Stay {
        opacity: .4;
        pointer-events: none;
        transform: scale(.8) rotate(-25deg);
        z-index: 2;
      }
      &.Gone {
        @include transitionExpo(.6);
        opacity: 0;
        pointer-events: none;
        transform: scale(1.2) rotate(15deg);
        z-index: 4;
      }
    }
  }
}


.topNews {
  @include mqDF() {
    padding-bottom: 78px;
    padding-top: 90px;
  }
  padding-bottom: 120px;
  padding-top: 122px;
  position: relative;
  z-index: 3;
  .l_titleSide {
    @include mqDF() {
      margin-bottom: 18px;
      width: 100%;
    }
    width: 26%;
  }
  .l_bodySide {
    @include mqDF() {
      padding-top: 0;
      width: 100%;
    }
    padding-top: 22px;
    width: 74%;
    .m_viewLink {
      @include mqDF() {
        margin-left: unset;
        margin-top: 20px;
        width: 130px;
      }
      margin-left: auto;
      margin-top: 40px;
      width: 160px;
    }
  }
}

.topCareers {
  aspect-ratio: 1440 / 502;
  background: url("../images/careers_bg.jpg") no-repeat center;
  background-size: cover;
  @include mqDF() {
    aspect-ratio: 375 / 236;
    background: url("../images/careers_bg_sp.jpg") no-repeat center;
    background-size: cover;
  }
  overflow: hidden;
  position: relative;
  width: 100%;
  .topCareersInnerWrap {
    height: 100%;
    position: relative;
    width: 100%;
    &::after {
      background: #0c0c0c;
      content: "";
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 50%;
      z-index: 10;
    }
    &::before {
      background: #0c0c0c;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 50%;
      z-index: 10;
    }
  }

  .topCareersInner {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }
  .topCareersBody {
    animation-delay: .5s;
    text-align: center;
    h2 {
      font-size: 7.2rem;
      font-weight: bold;
      @include interFont;
      @include mqDF() {
        font-size: 4.8rem;
      }
      line-height: 1;
    }
    p {
      font-size: 2.4rem;
      // font-weight: bold;
      @include interFont;
      @include mqDF() {
        font-size: 1.6rem;
        padding: 14px 0 22px;
      }
      line-height: 1;
      padding: 20px 0 46px;
    }
    .m_wantedButton {
      @include mqDF() {
        width: 219px;//
      }
      width: 337px;//100%;
    }
  }
}

.topContactFrom {
  @include mqDF() {
    padding-bottom: 78px;
    padding-top: 90px;
  }
  padding-bottom: 120px;
  padding-top: 120px;
  position: relative;
  z-index: 3;
  .l_titleSide {
    @include mqDF() {
      margin-bottom: 18px;
      width: 100%;
    }
    width: 40%;
  }
  .l_bodySide {
    @include mqDF() {
      padding-top: 0;
      width: 100%;
    }
    padding-top: 22px;
    width: 60%;
    .m_viewLink {
      @include mqDF() {
        margin-left: unset;
        margin-top: 20px;
        width: 130px;
      }
      // margin-left: auto;
      margin-top: 30px;//40px;
      width: 160px;
    }
  }
}


.topContactFromConf {
  background: #161616;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  &.Hide {
    opacity: 0;
    transition: all 0s;
    // display: none;
    visibility: hidden;
    .topContactFromConfInner {
      opacity: 0;
      visibility: hidden;
    }
  }
  &.Act {
    opacity: 1;
    // display: block;
    visibility: visible;
    .topContactFromConfInner {
      opacity: 1;
      visibility: visible;
    }
  }
  &.Fade {
    opacity: 0;
    transition: all .4s;
  }
  .topContactFromConfInner {
    align-items: center;
    display: flex;
    height: 100%;
    @include mqDF() {
      align-items: flex-start;
      overflow: scroll;
      padding-bottom: 60px;
      padding-top: 60px;
    }
    justify-content: center;
    opacity: 0;
    transition: all .5s;
  }
  .topContactFromConfBody {
    width: 100%;
    h4 {
      font-size: 2rem;
      margin-bottom: 40px;
    }
    .m_viewLink {
      @include mqDF() {
        margin-left: unset;
        margin-top: 30px;
        width: 130px;
      }
      margin-top: 30px;//40px;
      width: 160px;
    }
    &.Hide {
      display: none;
    }
  }
  .topContactFromConfBodyInner {
    .l_contentInner {
      @include mqDF() {
      }
    }
    .l_titleSide {
      @include mqDF() {
        margin-top: 0;
        padding-right: 0;
        width: 100%;
      }
      margin-top: 1.5%;
      padding-right: 10%;
      width: 40%;
    }
    .l_bodySide {
      height: 100dvh;
      @include mqDF() {
        height: 100%;
        margin-top: 28px;
        overflow: unset;
        padding: 0;
        width: 100%;
      }
      overflow: scroll;
      padding: 50px 0 50px;
      width: 60%;
    }
  }
  .topContactFromConfButtons {
    .m_backLink {
      margin-top: 32px;
    }
  }
  .topContactFromFinBody {
    @include mqDF() {
      align-items: center;
      display: flex;
      height: 100%;
    }
    opacity: 1;
    text-align: center;
    transition: opacity .4s;
    width: auto;
    h4 {
      font-size: 3.2rem;
      @include mqDF() {
        font-size: 2.4rem;
        margin-bottom: 16px;
      }
      letter-spacing: .04em;
      margin-bottom: 22px;
    }
    p {
      font-size: 1.6rem;
      @include mqDF() {
        font-size: 1.4rem;
      }
      letter-spacing: .04em;
    }
    .m_backLink {
      display: flex;
      @include mqDF() {
        margin: 30px auto 0;
      }
      justify-content: center;
      margin: 60px auto 0;
    }
    .m_viewLink {
      @include mqDF() {
        margin-left: unset;
        margin-top: 20px;
        width: 130px;
      }
      margin-top: 30px;//40px;
      width: 160px;
    }
    &.Hide {
      height: 0;
      opacity: 0;
      position: absolute;
      visibility: hidden;
      width: 0;
    }
  }
}

.topContact {
  @include mqDF() {
    padding-bottom: 80px;
    padding-top: 60px;
  }
  padding-bottom: 120px;
  padding-top: 120px;
  position: relative;
  z-index: 3;
  h2 {
    font-size: 12rem;
    font-weight: bold;
    @include interFont;
    @include mqDF() {
      font-size: 5rem;
      margin-bottom: 16px;;
    }
    letter-spacing: .04em;
  }

  ul {
    li {
      border-bottom: solid 1px $color-gray-c;
      font-size: 6.4rem;
      @include interFont;
      @include mqDF() {
        font-size: 3.2rem;//3.2em;
      }
      letter-spacing: .04em;
      position: relative;
      &:first-child {
        padding-top: 0;
      }
      a {
        color: $color-green;
        padding: 16px 0;
      }
    }
  }
}

html,
body,
a {
  cursor: none;
  @include mqDF() {
    cursor: auto;
  }
}
#stalker {
  align-items: center;
  display: flex;
  height: 28px;
  @include mqDF() {
    display: none;
  }
  // transition: transform 0.2s;  //ちょっと遅れてついてくるように
  // transition-timing-function: ease-out;
  // transform: translate(0,0);
  // opacity: 0;
  // transform: scale(0);
  // @include transitionExpo;
  @include mqDF() {
    display: none;
  }
  justify-content: center;
  left: -14px;    //座標調節（カーソル位置と円の中心を合わせる）
  // background: $color-gray-a;
  // border: solid 1px $color-white;
  // border-radius: 50%;
  mix-blend-mode: exclusion;
  pointer-events: none;
  position: fixed;
  top: -14px;     //座標調節（カーソル位置と円の中心を合わせる）
  width: 28px;
  z-index: 1000;

  span {
    background: $color-green;
    border-radius: 50%;
    display: block;
    height: 6px;
    @include transitionExpo();
    width: 6px;
  }
  &.Hover {
    span {
      height: 28px;
      width: 28px;
    }
  }
}


#stalkerOuter {
  align-items: center;
  // background: $color-gray-a;
  border: solid 1px $color-white;
  border-radius: 50%;
  display: flex;
  height: 28px;
  @include mqDF() {
    display: none;
  }
  @include transitionExpo;
  justify-content: center;
  left: -14px;    //座標調節（カーソル位置と円の中心を合わせる）
  mix-blend-mode: exclusion;
  pointer-events: none;
  position: fixed;
  top: -14px;     //座標調節（カーソル位置と円の中心を合わせる）
  width: 28px;
  z-index: 1000;
}


.topMembersSlide {
  @include mqDF() {
    padding-bottom: 80px;
    padding-top: 80px;
  }
  padding-bottom: 100px;
  padding-top: 164px;
  position: relative;

  .topMembersSlideWrap {
    @include mqDF() {
      padding-left: 5%;
    }
    margin-left: auto;
    margin-right: auto;
    padding-left: 8.33%;
  }
  .swiper {
    @include mqDF() {
      margin-top: 26px;
    }
    margin-top: 43px;
  }
  .swiper-wrapper {
    transition: all .5s cubic-bezier(.7, .33, .12, .98);
  }

  .topMemberBodyInner {
    .topMembersCard {
      @include mqDF() {
        max-width: 100%;
      }
      max-width: 297px;
    }
  }
  .topMembersCard {
    .topMembersCardImage {
      position: relative;
      width: 100%;
      &:hover {
        &::after {
          @include mqDF() {
            opacity: 0;
          }
          opacity: 1;
        }
      }
      &::after {
        background: $color-green;
        content: "";
        height: 100%;
        @include transitionExpo;
        left: 0;
        mix-blend-mode: soft-light;
        opacity: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 2;
      }
    }
    .topMembersCardName {
      margin-top: 14px;
      dt {
        font-size: 3.2rem;
        font-weight: bold;
        @include interFont;
        @include mqDF() {
          font-size: 2.4rem;
        }
        line-height: 1;
      }
      dd {
        color: $color-green;
        font-size: 1.6rem;
        @include interFont;
        @include mqDF() {
          font-size: 1.2rem;
          margin-top: 4px;
        }
        line-height: 1;
        margin-top: 6px;
      }
    }
  }

  .topMembersSlideLine {
    background: #999;
    height: 1px;
    @include mqDF() {
      margin-top: 30px;
    }
    margin-top: 38px;
    width: 100%;
    .topMembersSlideLineInner {
      background: #fff;
      height: 1px;
      width: 20%;
    }
  }

  .swiper-scrollbar {
    background: #999;
    height: 1px;
    @include mqDF() {
      margin-top: 30px;
    }
    margin-top: 38px;
    width: 100%;
  }
  .swiper-scrollbar-drag {
    background: #fff;
    height: 1px;
    width: 20%;
  }
}
.topMemberModal {
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  transition: all 1s cubic-bezier(.01, .8, .33, 1.14);
  width: 100%;
  z-index: 999;
  &.Act {
    display: block;
    opacity: 1;
  }
  &.ActOut {
    opacity: 0;
  }
  &.ActInner {
    .topMemberModalInner {
      opacity: 1;
    }
    .topMemberModalContentOuter {
      opacity: 1;
      transform: scale(1);
      transition-delay: .1s;
    }
  }
  .topMemberModalInner {
    background: $color-black;
    height: 100%;
    opacity: 0;
    transition: all .3s cubic-bezier(.01, .8, .33, 1.14);//cubic-bezier(.64, .25, 0, 1);
    width: 100%;
  }

  .topMemberModalClose {
    height: 48px;
    position: absolute;
    right: 22px;
    top: 0;
    width: 64px;
    z-index: 10;
    span {
      background: $color-white;
      display: block;
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      width: 100%;
      &:nth-child(1) {
        transform: rotate(13deg);
      }
      &:nth-child(2) {
        transform: rotate(-13deg);
      }
    }
  }
  .topMemberModalContentOuter {
    align-items: center;
    display: flex;
    height: 100%;
    @include mqDF() {
      display: block;
    }
    justify-content: center;
    opacity: 0;
    transform: scale(.99);
    transition: transform .3s cubic-bezier(.01, .8, .33, 1.14),
    opacity .6s cubic-bezier(.01, .8, .33, 1.14);
    width: 100%;
  }
  .topMemberModalContent {
    display: flex;
    display: none;
    gap: 0 10%;
    @include mqDF() {
      // display: block;
      height: 100%;
      overflow: scroll;
      padding: 99px 5%;
      width: 100%;
    }
    // pointer-events: none;
    width: 83%;
    &.Act {
      display: flex;
      @include mqDF() {
        display: block;
      }
    }

    &.ActInner {
      .topMemberModalImage {
        opacity: 1;
        transform: scale(1);
        transition: all .45s cubic-bezier(.01, .8, .33, 1.14);
      }
      .topMemberModalBody {
        opacity: 1;
        transform: scale(1);
        transition: all .45s cubic-bezier(.01, .8, .33, 1.14);
      }
    }
    .topMemberModalImage {
      @include mqDF() {
        width: 100%;
      }
      opacity: 0;
      transform: scale(.99);
      transform-origin: center;
      transition: all .45s cubic-bezier(.01, .8, .33, 1.14);
      width: 31%;
    }
    .topMemberModalBody {
      @include mqDF() {
        width: 100%;
      }
      opacity: 0;
      position: relative;
      transform: scale(.99);
      transform-origin: center;
      transition: all .45s cubic-bezier(.01, .8, .33, 1.14);
      width: 60%;
      .topMemberModalBodyName {
        dt {
          font-size: 4.8rem;
          font-weight: bold;
          @include interFont;
          @include mqDF() {
            font-size: 3.2rem;
            margin-top: 28px;
          }
          letter-spacing: .2rem;
          line-height: 1;
        }
        dd {
          color: $color-green;
          font-size: 2.4rem;
          @include interFont;
          @include mqDF() {
            font-size: 1.6rem;
            margin-top: 10px;
          }
          line-height: 1;
          margin-top: 16px;
        }
      }
      .topMemberModalBodyRead {
        font-size: 1.8rem;
        @include mqDF() {
          font-size: 1.4rem;
          margin-top: 12px;
        }
        line-height: 1.6;
        margin-top: 40px;
      }
      .topMemberModalBodyNav {
        align-items: center;
        display: flex;
        gap: 0 40px;
        @include mqDF() {
          justify-content: center;
          margin-top: 30px;
        }
        margin-top: 73px;
        @media screen and (min-width: 1400px) {
          bottom: 0;
          left: 0;
          position: absolute;
        }
        .topMemberModalBodyNavL {
          background: url("../images/modal_arrow_l.svg") no-repeat center;
          height: 20px;
          width: 20px;
        }

        .topMemberModalBodyNavNum {
          font-size: 2.0rem;
          @include interFont;
          @include mqDF() {
            font-size: 1.6rem;
          }
        }
        .topMemberModalBodyNavR {
          background: url("../images/modal_arrow_r.svg") no-repeat center;
          height: 20px;
          width: 20px;
        }
      }
    }
  }
}

.topOurClients {
  background: $color-black;
  @include mqDF() {
    overflow: hidden;
    padding-bottom: 80px;
    padding-top: 3px;
  }
  padding-bottom: 160px;
  padding-top: 0;
  position: relative;

  &.En {
    // @include mqDF() {
    //   overflow: hidden;
    //   padding-bottom: 80px;
    //   padding-top: 36px;
    // }
    // padding-bottom: 100px;
    // padding-top: 100px;
  }
  .topOurClientsTitle {
    @include mqDF() {
      width: 110%;
    }
  }

  .topOurClientsList {
    @include mqDF() {
      margin-top: 26px;
      padding-left: 0;
      padding-right: 0;
    }
    margin-top: 56px;
    padding-left: 3%;
    padding-right: 3%;
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 53px 3%;
      @include mqDF() {
        gap: 20px 7%;
      }
      justify-content: center;
      // justify-content: space-between;
    }

    .topOurClientsListItem {
      @include mqDF() {
        width: 28%;
      }
      width: 15%;
      a {
        display: block;
        height: 100%;
        pointer-events: none;
        width: 100%;
      }
    };
  }
  .topOurClientsTikTok {
    align-items: center;
    background: $color-gray-a;
    display: flex;
    @include mqDF() {
      display: block;
      margin-top: 45px;
      padding: 20px 0;
    }
    margin-top: 90px;
    padding: 40px 0;
    &.En {
      @include interFont;
    }
    dl {
      @include mqDF() {
        width: 100%;
      }
      padding: 0 4.7%;
      width: 50%;
      dt {
        font-size: 2.4rem;
        font-weight: bold;
        @include interFont;
        @include mqDF() {
          font-size: 1.8rem;
        }
        letter-spacing: .2rem;
      }
      dd {
        font-size: 1.6rem;
        @include mqDF() {
          font-size: 1.4rem;
          margin-top: 8px;
        }
        margin-top: 10px;
      }
    }
    p {
      @include mqDF() {
        margin-top: 16px;
        padding: 0 0;
        width: 100%;
      }
      padding: 0 4.7%;
      width: 50%;
    }
  }
}


.topCreative {
  @include mqDF() {
    padding: 78px 0 124px 0;
  }
  padding: 168px 0 246px 0;
  .l_contentInner {
    display: flex;
    gap: 6.5%;
    @include mqDF() {
      display: block;
    }
    justify-content: space-between;
    @media screen and (min-width: 2400px) {
      padding-left: 4%;
      padding-right: 4%;
    }
  }
  .topCreativeTitle {
    @include mqDF() {
      margin-top: .25vw;
      width: 100%;
    }
    margin-top: .5vw;
    width: 39%;
    .topCreativeTitleLogo {
      @include mqDF() {
        width: 56.5%;
      }
      width: 71.5%;
    }
    .topCreativeTitleRead {
      font-size: 2.0rem;
      @include mqDF() {
        font-size: 1.4rem;
        margin-top: 21px;
      }
      line-height: 1.6;
      margin-top: 42px;

      &.En {
        @include interFont;
        letter-spacing: .1rem;
      }
    }
  }
  .topCreativeBody {
    @include mqDF() {
      margin-top: 20px;
      width: 100%;
    }
    width: 48%;
  }

  .topCreativeContWrap {
    @include mqDF() {
      margin-top: 58px;
    }
    &:last-child {
      @include mqDF() {
        margin-top: 88px;
      }
      margin-top: 130px;
    }
    .topCreativeCont {
      .topCreativeContTitle {
        align-items: flex-start;
        border-bottom: solid 1px rgba($color-white, $alpha: .5);
        display: flex;
        gap: 0 16px;
        @include mqDF() {
          display: block;
          gap: 0 8px;
          padding: 0 0 27px 0;
        }
        padding: 0 0 16px 0;
        span {
          color: $color-green;
          font-size: 2.4rem;
          @include interFont;
          @include mqDF() {
            display: block;
            font-size: 2.4rem;
          }
        }

        div {
          font-size: 3.2rem;
          font-weight: 400;
          @include mqDF() {
            font-size: 2.4rem;
            margin-top: -.1rem;
          }
          margin-top: -.4rem;
        }
        &.En {
          @include interFont;
          @include mqDF() {
            padding: 0 0 16px 0;
          }
          div {
            @include mqDF() {
              // margin-top: -.3rem;
            }
            // margin-top: -.7rem;
          }
        }
      }
      .topCreativeContBody {
        @include mqDF() {
          margin-top: 28px;
        }
        margin-top: 22px;

        &.En {
          @include interFont;
          @include mqDF() {
            margin-top: 23px;
          }
          margin-top: 18px;
        }
      }
      .topCreativeContCountWrap {
        display: flex;
        // @include mqDF() {
        //   display: block;
        // }
        justify-content: space-between;
        position: relative;
        &.En {
          align-items: flex-end;
        }
      }
      .topCreativeContCountNote {
        bottom: -21%;
        font-size: 1.0rem;
        @include mqDF() {
          bottom: -30%;
        }
        position: absolute;
        right: 0;

        &.En {
          bottom: -14%;
          @include mqDF() {
            bottom: -30%;
          }
        }
      }
      .topCreativeContCount {
        @include mqDF() {
          // &:nth-child(2) {
          //   margin-top: 24px;
          // }
        }
        .topCreativeContCountTitle {
          font-size: 2.0rem;
          @include mqDF() {
            font-size: 1.2rem;
            margin-bottom: 8px;
          }
          letter-spacing: .06em;
          margin-bottom: 14px;
          text-align: center;
          &.En {
            @include mqDF() {
              font-size: 1.3rem;
              margin-bottom: 6px;
            }
            margin-bottom: 6px;
          }
        }
        .topCreativeContCountNum {
          align-items: flex-end;
          display: flex;
          gap: 0 5px;
          @include mqDF() {
            justify-content: center;
          }
          &.En {
            span {
              font-size: 1.6vw;//9.6rem;
              font-weight: 500;
              @include mqDF() {
                font-size: 3.2vw;
              }
              @media screen and (min-width: 1680px) {
                font-size: 3.3rem;
              }
              @media screen and (min-width: 1900px) {
                font-size: 2.8rem;
              }
              @media screen and (min-width: 2400px) {
                font-size: 2.6rem;
              }
            }
            div {
              display: flex;
              font-size: 5.5vw;
              font-weight: 700;
              @include mqDF() {
                font-size: 13vw;
                letter-spacing: -.1rem;
              }
              @media screen and (min-width: 1680px) {
                font-size: 8rem;
              }
              @media screen and (min-width: 1800px) {
                font-size: 7.5rem;
              }
              @media screen and (min-width: 1900px) {
                font-size: 7rem;
              }
              @media screen and (min-width: 2400px) {
                font-size: 6rem;
              }
            }
          }
          span {
            // font-size: 4.0rem;
            font-size: 2.7vw;//9.6rem;
            font-weight: 500;
            @include mqDF() {
              font-size: 5.5vw;
            }
            @media screen and (min-width: 1680px) {
              font-size: 4rem;
            }
            &:last-child {
              color: $color-green;
            }
          }

          div {
            color: $color-green;
            display: inline-block;
            font-size: 6vw;//6.7vw;//9.6rem;
            font-weight: 700;
            @include mqDF() {
              font-size: 13.8vw;//15vw;
              letter-spacing: -.1rem;
            }
            @include interFont;
            letter-spacing: -.32rem;
            line-height: 1;
            margin-bottom: -.5rem;
            position: relative;
            @media screen and (min-width: 1600px) {
              font-size: 9rem;
            }
            @media screen and (min-width: 1680px) {
              font-size: 9rem;
            }
            i {
              color: rgba($color-white, $alpha: .8);
              font-size: 1.0rem;
              font-style: normal;
              @include mqDF() {
                right: -15%;
                top: 10%;
              }
              position: absolute;
              right: -11%;
              top: 10%;
            }
          }
        }
      }
    }
  }
}


.topCommu {
  @include mqDF() {
    padding: 84px 0 100px 0;
  }
  padding: 156px 0 94px 0;
  position: relative;
  .topCommuBg {
    background: url("../images/top_commu_bg.jpg") no-repeat center;
    background-size: cover;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .l_contentInner {
    display: flex;
    gap: 6.5%;
    @include mqDF() {
      display: block;
      // padding-bottom: 0;
      // padding-left: 5%;
      // padding-right: 5%;
      // padding-top: 80px;
    }
    position: relative;
    z-index: 2;
  }
  .topCommuTitle {
    @include mqDF() {
      width: 100%;
    }
    width: 39%;
    .topCommuTitleLogo {
      @include mqDF() {
        width: 80%;
      }
    }
    .topCommuTitleRead {
      font-size: 2.0rem;
      @include mqDF() {
        font-size: 1.4rem;
      }
      line-height: 1.6;
      margin-top: 23px;
      &.En {
        @include interFont;
      }
    }
  }
  .topCommuTitleCount {
    @include mqDF() {
      margin-top: 34px;
    }
    margin-top: 38px;
    &.En {
      @include interFont;
      @include mqDF() {
        overflow: hidden;
        width: 100%;
      }
      width: 150%;
      p {
        @include mqDF() {
          font-size: 2.5rem;
          letter-spacing: .1rem;
        }
      }
    }
    p {
      font-size: 4.4rem;
      font-weight: 500;
      @include mqDF() {
        font-size: 3.2rem;
      }
    }
    .topCommuTitleCountNum {
      align-items: flex-end;
      display: flex;
      @include mqDF() {
        margin: 0 0 0 0;
      }
      margin: 8px 0 0 0;

      &.En {
        gap: 0 1.5%;
        margin: 0;
        span {
          color: $color-white;
          font-size: 2.2vw;
          font-weight: normal;
          @include mqDF() {
            display: block;
            font-size: 7vw;
            margin-bottom: -.5rem;
          }
        }
      }
      div {
        color: $color-green;
        display: inline-block;
        font-size: 6.6vw;//9.6rem;
        font-weight: 700;
        @include interFont;
        @include mqDF() {
          font-size: 20vw;
          letter-spacing: -.2rem;
        }
        letter-spacing: -.2rem;
        line-height: 1;
        margin-bottom: -.5rem;
        position: relative;

        &:nth-child(3) {
          margin-left: -1.4%;
        }
      }
      span {
        color: $color-green;
        // font-size: 4.0rem;
        font-size: 3vw;
        font-weight: bold;
        @include mqDF() {
          font-size: 9vw;
        }
      }
    }
  }
  .topCommuBody {
    @include mqDF() {
      margin-top: 55px;
      width: 100%;
    }
    width: 48%;
  }
}


.topWhatRe {
  @include mqDF() {
    padding-bottom: 80px;
    padding-top: 37px;
  }
  padding-bottom: 213px;
  padding-top: 112px;
  h2 {
    @include mqDF() {
      padding-bottom: 18px;
      padding-top: 45px;
      width: 100%;
    }
    padding-bottom: 38px;
    padding-top: 100px;
    width: 90%;
  }
  p {
    border-top: solid 1px #999;
    font-size: 2.4rem;
    @include mqDF() {
      font-size: 1.4rem;
      line-height: 1.8;
      padding-top: 12px;
    }
    padding-top: 36px;
    text-align: justify;
    span {
      color: $color-gray-a;
    }

    &.En {
      @include interFont;
      // letter-spacing: .1rem;
      text-align: left;
    }
  }
  .topWhatReView {
    margin-top: 32px;
    width: 200px;
  }

  &.En {
    @include mqDF() {
      padding-bottom: 68px;
    }
    .topWhatReView {
      @include mqDF() {
        margin-top: 0;
      }
    }
  }
}
