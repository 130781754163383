@charset "UTF-8";
// ==========================================================================
// NewsPage
// ==========================================================================
@use "../configs/" as *;

.projectPage {
  .l_secondaryContent {
    @include mqDF() {
      padding-bottom: 86px;
      padding-top: 86px;
    }
    padding-bottom: 128px;
    padding-top: 120px;
  }
  .c_catNav {
    @include mqDF() {
      margin-top: 5px;
    }
    margin-top: 20px;
  }
  .projectPageListWrap {
    @include mqDF() {
      margin-top: 32px;
    }
    margin-top: 83px;
  }
  .c_pagerNavWrap {
    @include mqDF() {
      margin-top: 42px;
    }
    margin-top: 60px;
  }
}

.projectDetailPage {
  .projectDetailBody {
    @include mqDF() {
      padding-left: 5%;
      padding-right: 5%;
    }
    padding-left: 15%;
    padding-right: 15%;
  }
  .projectDetailMovFrame {
    aspect-ratio: 264/528;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    // width: 24%;
    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
    .m_projectCardImageMotion {
      display: none;
    }
    &:hover {
      .m_projectCardImageStatic {
        display: none;
      }
      .m_projectCardImageMotion {
        display: block;
      }
    }
  }
  .projectDetailDL {
    display: flex;
    dt {
      color: #a2a2a2;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      @include interFont;
      @include mqDF() {
        font-size: 1.2rem;
      }
      letter-spacing: .04em;
      text-transform: uppercase;
    }
    dd {
      color: #fff;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 500;
      @include interFont;
      @include mqDF() {
        font-size: 1.2rem;
      }
      letter-spacing: .04em;
      text-transform: uppercase;
    }
  }


  .projectDetailKV {
    align-items: center;
    display: flex;
    // height: 100vh;
    // background: $color-black;
    height: 768px;
    @include mqDF() {
      height: auto;
      justify-content: start;
      min-height: auto;
      padding-left: 5%;
      padding-right: 5%;
      padding-top: 80px;
    }
    justify-content: center;
    min-height: 750px;
    position: relative;
    width: 100%;
    .projectDetailKVImage {
      background: $color-black;
      height: 100%;
      @include mqDF() {
        display: none;
      }
      opacity: .2;
      position: absolute;
      width: 100%;
      &::after {
        background: url("../images/mesh_bg.png") repeat center;
        content: "";
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        // mix-blend-mode: multiply;
      }
    }
    .projectDetailKVBody {
      align-items: center;
      display: flex;
      @include mqDF() {
        margin-left: 0;
      }
      justify-content: center;
      margin-left: -7%;
      position: relative;
      z-index: 2;

      @media screen and (max-width: 1100px) {
        margin-left: -4%;
      }
      @media screen and (max-width: 900px) {
        margin-left: 0;
      }
      .projectDetailKVTitle {
        @include mqDF() {
          margin-right: 0;
          width: 100%;
        }
        margin-right: 13%;
        @media screen and (max-width: 1100px) {
          margin-right: 10%;
        }
        @media screen and (max-width: 900px) {
          margin-right: 6%;
        }
        // width: 76%;
        dt {
          color: $color-green;
          font-size: 1.6rem;
          font-style: normal;
          font-weight: 500;
          @include interFont;
          @include mqDF() {
            font-size: 1.2rem;
          }
          letter-spacing: .04em;
          text-transform: uppercase;
        }
        dd {
          font-size: 4.0rem;
          font-style: normal;
          font-weight: 700;
          @include interFont;
          @include mqDF() {
            font-size: 2.4rem;
            margin-top: 5px;
          }
          letter-spacing: .04em;
          margin-top: 10px;
          text-transform: uppercase;
        }
      }


      .projectDetailKVMov {
        @include mqDF() {
          display: none;
        }
        min-width: 270px;
        // margin-left: 7%;
        width: 24%;
        @media screen and (max-width: 1100px) {
          // margin-left: 7%;
          width: 27%;
        }
        @media screen and (max-width: 900px) {
          // margin-left: 7%;
          width: 30%;
        }
      }
    }
  }


  .projectDetailInfo {
    display: flex;
    @include mqDF() {
      display: block;
      padding-bottom: 60px;
      padding-top: 40px;
    }
    padding-bottom: 126px;
    padding-top: 120px;
    .projectDetailInfoDL {
      @include mqDF() {
        padding-right: 0;
        width: 100%;
      }
      padding-right: 3%;
      width: 42%;
      .projectDetailDL {
        @include mqDF() {
          margin-bottom: 12px;
        }
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        dt {
          width: 28%;
        }
        dd {
          width: 72%;
          word-break: break-all;
          a {
            &:hover {
              color: $color-green;
              @include mqDF() {
                color: #fff;
              }
            }
          }
        }
      }
    }
    .projectDetailInfoRead {
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 500;
      @include mqDF() {
        font-size: 1.4rem;
        font-weight: 400;
        margin-top: 40px;
        width: 100%;
      }
      letter-spacing: .04em;
      line-height: 1.6;
      width: 58%;
      strong {
        color: $color-green;
        font-weight: 500;
      }
    }
  }

  .projectDetailMovie {
    display: flex;
    flex-wrap: wrap;
    gap: 0 5%;
    @include mqDF() {
      padding-bottom: 16px;
    }
    margin: 0 auto;
    padding-bottom: 74px;
    // justify-content: center;
    width: 86%;
    .projectDetailMovFrameBox {
      @include mqDF() {
        width: 46%;
      }
      width: 47%;
      .projectDetailMovFrame {
        width: 100%;
      }
      &:not(:nth-child(1)) {
        &:nth-child(odd) {
          @include mqDF() {
            // margin-top: 11%;
          }
          margin-top: -5%;
          // transform: translateY(11.5%);
        }
        &:nth-child(even) {
          @include mqDF() {
            // margin-top: 11%;
          }
          margin-top: 5%;
          // transform: translateY(11.5%);
        }
        &:nth-child(2) {
          @include mqDF() {
            // margin-top: 11%;
          }
          margin-top: 11%;
          // transform: translateY(11.5%);
        }
      }
    }
  }

  .projectDetailImages {
    .projectDetailImagesOne {
      @include mqDF() {
        margin-top: 24px;
      }
      margin-top: 48px;
      &:first-child {
        // margin-top: 0;
      }
    }
  }

  .projectDetailCredit {
    @include mqDF() {
      padding-bottom: 86px;
      padding-top: 60px;
    }
    padding-bottom: 132px;
    padding-top: 123px;
    .projectDetailCreditTitle {
      font-feature-settings: "halt" on;
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 800;
      @include interFont;
      @include mqDF() {
        font-size: 1.6rem;
      }
      letter-spacing: .04em;
    }
    .projectDetailDL {
      @include mqDF() {
        margin-top: 13px;
        width: 100%;
      }
      margin-top: 26px;
      dt {
        @include mqDF() {
          width: 50%;
        }
        width: 26%;
      }
      dd {
        @include mqDF() {
          width: 50%;
        }
        width: 74%;
      }
    }
  }

  .projectDetailOther {
    @include mqDF() {
      padding-bottom: 80px;
    }
    padding-bottom: 160px;
    padding-left: 8%;
    padding-right: 8%;
    .m_sectionTitle {
      margin-bottom: 42px;
    }
  }
}

.loader {
  animation: load3 1.4s infinite linear;
  background: #fff;
  background: linear-gradient(to right, #fff 10%, rgba(255, 255, 255, 0) 42%);
  border-radius: 50%;
  font-size: 10px;
  height: 40px;
  @include mqDF() {
    height: 24px;
    width: 24px;
  }
  margin: 50px auto;
  overflow: hidden;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  width: 40px;
}
.loader:before {
  background: #fff;
  border-radius: 100% 0 0 0;
  content: "";
  height: 50%;
  left: 0;
  position: absolute;
  top: 0;
  width: 50%;
}
.loader:after {
  background: #161616;
  border-radius: 50%;
  bottom: 0;
  content: "";
  height: 75%;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 75%;
}
@-webkit-keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.projectLoader {
  @include mqDF() {
    margin-top: 32px;
  }
  margin-top: 80px;
  &.Out {
    display: none;
  }
}
