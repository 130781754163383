@charset "UTF-8";
// ==========================================================================
// Base – CSS animation
// ==========================================================================
@use "../configs/" as *;


.js-inView {
  opacity: 0;
}
.js-inViewAct {
  animation: inViewActKey .66s;
  // animation-timing-function: cubic-bezier(0.625, 0.080, 0.055, 0.920);
  // animation-timing-function:  cubic-bezier(0.425, 0.105, 0.000, 0.985);
  animation-delay: .25s;
  animation-fill-mode: forwards;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
  &.Delay {
    animation-delay: .28s;
  }
}
@keyframes inViewActKey {
  0% {
    opacity: 0;
    transform: translateY(15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.js-inViewB {
  opacity: 0;
}
.js-inViewBAct {
  animation: inViewBActKey .66s;
  // animation-timing-function: cubic-bezier(0.625, 0.080, 0.055, 0.920);
  // animation-timing-function:  cubic-bezier(0.425, 0.105, 0.000, 0.985);
  animation-delay: .25s;
  animation-fill-mode: forwards;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
  &.Delay {
    animation-delay: .28s;
  }
}
@keyframes inViewBActKey {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.js-inViewSplit {
  opacity: 0;
  span {
    opacity: 0;
  }
}



.js-inViewC {
  opacity: 0;
}
.js-inViewCAct {
  animation: inViewCActKey .46s;
  // animation-timing-function: cubic-bezier(0.625, 0.080, 0.055, 0.920);
  // animation-timing-function:  cubic-bezier(0.425, 0.105, 0.000, 0.985);
  animation-delay: .25s;
  animation-fill-mode: forwards;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
  &.Delay {
    animation-delay: .28s;
  }
}
@keyframes inViewCActKey {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}


.js-inViewSplitAct {
  animation: inViewSplitActKey .5s;
  animation-fill-mode: forwards;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;


  &:nth-child(2) {
    animation-delay: .2s;
  }
  &:nth-child(3) {
    animation-delay: .3s;
  }
  &:nth-child(4) {
    animation-delay: .4s;
  }
  &:nth-child(5) {
    animation-delay: .45s;
  }
  &:nth-child(6) {
    animation-delay: .5s;
  }
  &:nth-child(7) {
    animation-delay: .53s;
  }
  &:nth-child(8) {
    animation-delay: .56s;
  }
  &:nth-child(9) {
    animation-delay: .59s;
  }
  &:nth-child(10) {
    animation-delay: .6s;
  }
  span {
    animation: inViewStateActKey .65s;
    animation-delay: .0s;
    animation-fill-mode: forwards;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    display: inline-block;
    transform-origin: top left;
    will-change: transform;


    &:nth-child(2) {
      animation-delay: .2s;
    }
    &:nth-child(3) {
      animation-delay: .3s;
    }
    &:nth-child(4) {
      animation-delay: .4s;
    }
    &:nth-child(5) {
      animation-delay: .5s;
    }
    &:nth-child(6) {
      animation-delay: .55s;
    }
    &:nth-child(7) {
      animation-delay: .6s;
    }
    &:nth-child(8) {
      animation-delay: .62s;
    }
    &:nth-child(9) {
      animation-delay: .64s;
    }
    &:nth-child(10) {
      animation-delay: .66s;
    }
    &:nth-child(11) {
      animation-delay: .66s;
    }
    &:nth-child(12) {
      animation-delay: .66s;
    }
    &:nth-child(13) {
      animation-delay: .66s;
    }
    &:nth-child(14) {
      animation-delay: .66s;
    }
    &:nth-child(15) {
      animation-delay: .66s;
    }
    &:nth-child(16) {
      animation-delay: .66s;
    }
    &:nth-child(17) {
      animation-delay: .66s;
    }
    &:nth-child(18) {
      animation-delay: .66s;
    }
    &:nth-child(19) {
      animation-delay: .66s;
    }
  }
}
@keyframes inViewSplitActKey {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes inViewStateActKey {
  0% {
    opacity: 0;
    transform: matrix(1, .1, 0, 1.15, 0, 0);
  }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0);
  }
}

.js-inViewAlpha {
  opacity: 0;
}
.js-inViewAlphaAct {
  animation: inViewAlphaActKey 1s;
  animation-delay: .25s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.425, .105, .000, .985);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
  &.Delay {
    animation-delay: .28s;
  }
}
@keyframes inViewAlphaActKey {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.js-inViewSL {
  opacity: 0;
}
.js-inViewSLAct {
  animation: inViewAlphaActKey 1.3s;
  animation-delay: .25s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.425, .105, .000, .985);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  will-change: transform;
  &.Delay {
    animation-delay: .28s;
  }
}
@keyframes inViewAlphaActKey {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.js-TickerLoop {
  animation: inMotionTickerLoop 25s;
  animation-direction: normal;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.250, .250, .750, .750);
  // will-change: transform;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  content-visibility: auto;
}

@keyframes inMotionTickerLoop {
  0% {
    transform: translate3d(0,0,0);
  }
  100% {
    transform: translate3d(-100%,0,0);
  }
}


@mixin transitionC {
  transition: all .45s cubic-bezier(.215, .61, .355, 1);
}
.js-inViewMatrixA {
  @include transitionC;
  transform: scale(1.05);
  transition-duration: 1s;
  &::after {
    @include transitionC;
    transform-origin: top right;
  }
  &::before {
    @include transitionC;
    transform-origin: top left;
  }
}
.js-inViewMatrixAAct {
  transform: scale(1);
  &::after {
    transform: scale(0, 1);
  }
  &::before {
    transform: scale(0, 1);
  }
}


.js-inViewInfinite {
  animation: inViewInfiniteKey 1.3s;
  animation-delay: .25s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.425, .105, .000, .985);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  opacity: 0;
  transform: translate3d(0, 10%, 0);
  will-change: transform;
}

@keyframes inViewInfiniteKey {
  0% {
    opacity: 0;
    transform: translateY(5%);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
