@charset "UTF-8";
// ==========================================================================
// Base – CSS Mixin
// ==========================================================================
@use "./variables" as *;
@use "sass:math";


@mixin interFont {
  font-family: "Inter", sans-serif;
}


@mixin transition($sec: .5) {
  transition: all $sec+s cubic-bezier(.010, .800, .330, 1.140);
}
@mixin transitionExpo($sec: .5) {
  transition: all $sec+s cubic-bezier(.190, 1.000, .220, 1.000);
}

@mixin underHover($color: #fff, $pos: 20%) {
  position: relative;
  text-decoration: none;
  &::after {
    background: $color;
    bottom: $pos; //20%;//$pos + px;
    content: "";
    height: 1px;
    @include transitionExpo(.5);
    // transform-origin: center left;
    @include mqDF() {
      height: 1px;
    }
    left: 0;
    position: absolute;
    transform: scale(0, 1);
    width: 100%;
  }
  &:hover::after {
    animation: inUnderHoverKey  .5s;
    // animation-delay: .1s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.785, .135, .150, .860);
    backface-visibility: hidden;
    @include mqDF() {
      transform: scale(1, 1);
    }
    // transform: scale(1, 1);
    transform-origin: center right;
    will-change: transform;
  }
}

@keyframes inUnderHoverKey {
  0% {
    transform: scale(0, 1);
    transform-origin: center left;
  }
  49.5% {
    transform: scale(1, 1);
    transform-origin: center left;
  }
  55% {
    transform: scale(1, 1);
    transform-origin: center right;
  }
  100% {
    transform: scale(0, 1);
    transform-origin: center right;
  }
  // 100% {
  //   transform: scale(1,1);
  // }
}


@function get_vw($size, $viewport: 375) {
  $rate: math.div(100, $viewport);
  @return $rate * $size * 1vw;
}

@mixin fz-vw($font-size: 10, $viewport: 375) {
  font-size: $font-size * .1rem;
  font-size: get_vw($font-size, $viewport);
  @media only screen and (min-width: 1440px) {
    font-size: $font-size * .1rem;
  }
}


@mixin fz-vwNoLimit($font-size: 10, $viewport: 375) {
  font-size: $font-size * .1rem;
  font-size: get_vw($font-size, $viewport);
}
@mixin maxWidth($max-size: 1280px) {
  max-width: $max-size;
}
@mixin minWidth($max-size: 1280px) {
  min-width: $max-size;
}
@mixin maxWidthCenter($max-size: 1280px) {
  margin-left: auto;
  margin-right: auto;
  max-width: $max-size;
}

@mixin letterSpace($font-size: 12, $letter: .08) {
  letter-spacing: $rate;
  $rate: $font-size*$letter+rem;
}

@mixin lineHeight($font-size: 1.5, $line: 1) {
  line-height: $rate;
  $rate: $font-size*$line+rem;
}

@mixin sideSpace() {
  @include mqDF() {
    padding-left: 4%;
    padding-right: 4%;
  }
  padding-left: 3.4%;
  padding-right: 3.4%;
}
@mixin sideNoSpace() {
  padding-left: 0;
  padding-right: 0;
}

$breakpoints: (
  "sm": "screen and (min-width: 400px)",
  "md": "screen and (min-width: 768px)",
  "lg": "screen and (min-width: 1080px)",
  "xl": "screen and (min-width: 1200px)",
  "xxl": "screen and (min-width: 1800px)",
  "xxxl": "screen and (min-width: 2500px)",
) !default;

@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
};
$breakpointsMax: (
  "sm": "screen and (max-width: 400px)",
  "md": "screen and (max-width: 768px)",
  "mdb": "screen and (max-width: 800px)",
  "lgb": "screen and (max-width: 1000px)",
  "lg": "screen and (max-width: 1080px)",
  "xl": "screen and (max-width: 1200px)",
  "xxl": "screen and (max-width: 1200px)",
) !default;

@mixin mqDF($breakpoint: md) {
  @media #{map-get($breakpointsMax, $breakpoint)} {
    @content;
  }
};
