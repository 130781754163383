@charset "UTF-8";
// ==========================================================================
// Base – Global
// ==========================================================================
@use '../configs/variables' as *;

html {
  height: 100%;
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: $default-font-family;
  font-size: $default-font-size;
  line-height: $default-line-height;
  color: $color-white;
  background-color: $color-bg;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
/* Avoid Chrome to see Safari hack */
// @supports (-webkit-touch-callout: none) {
//   body {
//     /* The hack for Safari */
//     height: -webkit-fill-available;
//   }
// }
*{
  box-sizing:border-box;
}

::selection {
  color: $color-green;
  // background:$color-black;
}
