@charset "UTF-8";
// ==========================================================================
// image
// ==========================================================================
@use '../../configs/' as *;
.m_image{
  width: 100%;
  height: auto;
}
.m_imageVert{
  width: auto;
  height: 100%;
}
