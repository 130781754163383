@charset "UTF-8";
// ==========================================================================
// form
// ==========================================================================
@use "../../configs/" as *;

.m_formBoxOne {
  &.Conf{
    display: flex;
    align-items: flex-start;
    padding: 0 0 26px;
    border-bottom: 1px solid #999999;
    @include mqDF() {
      display: block;
      padding: 0 0 23px;
      dt{
        width: 100%;
        min-width: 100%;
        color: #A2A2A2;
      }
      dd{
        width: 100%;
        height: auto;
      }
    }
    dt{
      flex-shrink: 0;
      width: 28%;
      min-width: 208px;
      height: auto;
    }
    dd{
      width: 72%;
      height: auto;
      input {
        height: auto;
        padding: 0;
      }
      textarea {
        height: auto;
        padding: 0;
      }
    }
  }
  dt {
    margin-bottom: 8px;
    font-size: 1.4rem;
    color: $color-white;
    letter-spacing: .04em;
    @include interFont;
    &.Required {
      &::after {
        display: inline-block;
        padding-left: .2rem;
        color: $color-green;
        content: "*";
      }
    }
  }
  dd {
    input {
      width: 100%;
      height: 56px;
      padding: 0 10px;
      font-size: 1.6rem;
      background: $color-gray-e;
      &.Conf {
        pointer-events: none;
        background: none;
      }
    }
    .SelectWrap {
      position: relative;
      width: 100%;
      height: 56px;
      overflow: hidden;
      font-size: 1.6rem;
      background: $color-gray-e;
      &::after {
        position: absolute;
        top: calc(50% - 9px);
        right: 3%;
        width: 12px;
        height: 12px;
        content: "";
        border-top: solid 2px $color-white;
        border-right: solid 2px $color-white;
        transform: rotate(135deg);
      }
    }
    select {
      // padding: 0 10px;
      width: 100%;
      height: 56px;
      padding: 0 10px;
      // background: $color-gray-e;
      font-size: 1.6rem;
    }
    textarea {
      width: 100%;
      height: 56px;
      min-height: 130px;
      padding: 15px 10px 10px 10px;
      font-size: 1.6rem;
      resize: vertical;
      background: $color-gray-e;
      @include mqDF() {
        min-height: 250px;
      }
      &.Conf {
        pointer-events: none;
        background: none;
        @include mqDF() {
          min-height: auto;
        }
      }
    }
  }
}
