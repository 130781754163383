@charset "UTF-8";
// ==========================================================================
// header
// ==========================================================================
@use "../../configs/" as *;

body.fixed {
  @include mqDF() {
    min-height: 100vh; /* Fallback */
    min-height: calc(var(--vh, 1vh) * 100);
  }
}
html.fixed {
  @include mqDF() {
    min-height: 100vh; /* Fallback */
    min-height: calc(var(--vh, 1vh) * 100);
    overflow-y: scroll;
  }
}

.p_headerWrap {
  display: none;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  .p_headerBar {
    align-items: center;
    display: flex;
    height: 48px;
    @include mqDF() {
      justify-content: flex-end;
    }
    justify-content: space-between;
    padding: 0 22px;
  }
  &.View {
    display: block;
  }
}

.p_headerLangToggle {
  @include mqDF() {
    display: none;
  }
  &.InnerNav {
    display: none;
    @include mqDF() {
      display: flex;
      margin-top: 24px;
    }
  }
}
.p_headerNavToggle {
  height: 48px;
  position: relative;
  width: 64px;
  // background: gray;
  // cursor: pointer;
  span {
    background: $color-white;
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
    &:nth-child(1) {
      top: calc(50% - 4px);
    }
    &:nth-child(2) {
      top: calc(50% + 4px);
    }
  }
  &.Close {
    position: absolute;
    right: 22px;
    top: 0;
    z-index: 10;
    span {
      top: 50%;
      &:nth-child(1) {
        transform: rotate(13deg);
      }
      &:nth-child(2) {
        transform: rotate(-13deg);
      }
    }
  }
}

.p_headerNavWrap {
  background: #1f1f1f;// $color-gray-a;
  display: flex;
  height: 100%;
  @include transitionExpo(1);
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
  .p_headerNavLogo {
    align-items: center;
    background: $color-gray-b;
    display: flex;
    @include transitionExpo(1);
    @include mqDF() {
      display: none;
    }
    opacity: 0;
    transform: translateX(-50%);
    width: 60%;
    .p_headerNavLogoImage {
      margin-left: -2%;
      width: 85%;
    }
  }
  .p_headerNav {
    display: flex;
    @include transitionExpo(1);
    @include mqDF() {
      width: 100%;
    }
    justify-content: center;
    opacity: 0;
    transform: translateX(50%);
    width: 40%;

    .p_headerNavLogoImageSP {
      display: none;
      @include mqDF() {
        bottom: 5%;
        display: block;
        position: absolute;
        right: -4%;
        width: 82%;
      }
    }
    .p_headerNavInner {
      @include mqDF() {
        padding-left: 5.3%;
        padding-right: 5.3%;
      }
      overflow: scroll;
      padding-bottom: 86px;
      padding-left: 20%;
      padding-right: 20%;
      padding-top: 86px;
      width: 100%;
    }
    .p_headerNavList {
      width: 100%;
      .p_headerNavListItem {
        border-bottom: solid 1px $color-gray-c;
        font-size: 4.2rem;
        font-weight: bold;
        @include interFont;
        @include transitionExpo(1);
        letter-spacing: .04em;
        line-height: 1;
        opacity: 0;
        position: relative;
        transform: translateX(15%);
        &:nth-child(1) {
          transition-delay: .1s;
        }
        &:nth-child(2) {
          transition-delay: .13s;
        }
        &:nth-child(3) {
          transition-delay: .15s;
        }
        &:nth-child(4) {
          transition-delay: .17s;
        }
        &:nth-child(5) {
          transition-delay: .19s;
        }
        &:nth-child(6) {
          transition-delay: .2s;
        }
        a {
          display: inline-block;
          @include transitionExpo(1);
          padding-bottom: 14px;
          padding-top: 14px;
        }
        &::after {
          background: $color-green;
          bottom: -1px;
          content: "";
          height: 1px;
          left: 0;
          position: absolute;
          transform: scale(0, 1);
          transform-origin: left center;
          transition: all .4s cubic-bezier(.940, .000, .090, .940);
          width: 100%;
        }
        &:hover {
          // padding-left: 5%;
          a {
            transform: translateX(5%);
          }

          &::after {
            transform: scale(1, 1);
          }
        }
      }
    }
    .p_headerNavSubList {
      width: 60%;
      .p_headerNavSubListItem {
        border-bottom: solid 1px $color-gray-c;
        color: $color-green;
        font-size: 3.6rem;
        font-weight: 500;
        @include interFont;
        @include transitionExpo(1);
        letter-spacing: .04em;
        line-height: 1;
        opacity: 0;
        transform: translateX(20%);
        &:nth-child(1) {
          transition-delay: .17s;
        }
        &:nth-child(2) {
          transition-delay: .19s;
        }
        &:nth-child(3) {
          transition-delay: .21s;
        }
        a {
          display: inline-block;
          padding-bottom: 20px;
          padding-top: 20px;
        }

        &:hover {
          // padding-left: 5%;
        }
      }
    }

    .p_headerNavAddress,
    .p_headerNavInfo,
    .p_headerNavCopyRight {
      color: $color-gray-d;
      font-size: 1.2rem;
      line-height: 1.8;
      // width: 60%;
      // min-width: 320px;
      margin-top: 32px;
    }
    .p_headerNavInfo {
      margin-top: 24px;
      .p_headerNavInfoInner {
        display: flex;
        dt {
          flex-shrink: 0;
          // width: 50px;
          padding-right: .8rem;
        }
      }
    }
    .p_headerNavCopyRight {
      margin-top: 20px;
    }

    .p_headerNavSports {
      margin-top: 28px;
      width: 154px;
      a {
        display: block;
        @include transitionExpo(1);
        img {
          &:last-child {
            display: none;
          }
        }
      }

      &:hover {
        a {
          display: block;
          // transform: translateX(5%);
          img {
            &:first-child {
              display: none;
            }
            &:last-child {
              display: block;
            }
          }
        }
      }
    }
  }


  &.Act {
    opacity: 1;
    visibility: visible;
    .p_headerNavLogo {
      opacity: 1;
      transform: translateX(0);
    }
    .p_headerNav {
      opacity: 1;
      transform: translateX(0);

      .p_headerNavList {
        .p_headerNavListItem {
          opacity: 1;
          transform: translateX(0%);
        }
      }
      .p_headerNavSubList {
        .p_headerNavSubListItem {
          opacity: 1;
          transform: translateX(0%);
        }
      }
    }
  }

  &.Hide {
    opacity: 0;
  }
}

.p_langToggle {
  display: flex;
  .p_langToggleSlash {
    padding: 0 .5rem;
  }
  .p_langToggleItem {
    display: block;
    font-size: 1.6rem;
    font-weight: 500;
    @include interFont;
    position: relative;
    &.Act {
      &::after {
        background: $color-green;
        bottom: 0;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        width: 100%;
      }
    }
  }
}
