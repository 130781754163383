@charset "UTF-8";
// ==========================================================================
// NewsPage
// ==========================================================================
@use "../configs/" as *;

.newsPage {
  // overflow: hidden;
  .l_secondaryContent {
    @include mqDF() {
      padding-top: 86px;
      padding-bottom: 86px;
    }
    padding-top: 120px;
    padding-bottom: 128px;
  }
  .c_catNav {
    @include mqDF() {
      margin-top: 5px;
    }
    margin-top: 20px;
  }
  .newsPageListWrap {
    @include mqDF() {
      margin-top: 32px;
    }
    margin-top: 80px;
  }
  .c_pagerNavWrap {
    @include mqDF() {
      margin-top: 42px;
    }
    margin-top: 60px;
  }
}


.newsDetailPage {
  .l_secondaryContent {
    @include mqDF() {
      padding-top: 86px;
      padding-bottom: 86px;
    }
    padding-top: 120px;
    padding-bottom: 128px;
  }
  .newsDetailBody {
    .newsDetailInfo {
      display: flex;
      font-size: 1.6rem;
      color: $color-green;
      @include interFont;
      @include mqDF() {
        font-size: 1.2rem;
      }
      time {
        padding-left: 2rem;
        color: $color-gray-c;
        @include mqDF() {
          padding-left: 2rem;
        }
      }
    }
    .newsDetailTitle {
      display: block;
      margin-top: 26px;
      font-size: 4.0rem;
      line-height: 1.6;
      letter-spacing: .04em;
      @include mqDF() {
        margin-top: 8px;
        font-size: 2.0rem;
      }
    }

    .newsDetailHero {
      @include mqDF() {
        margin-top: 40px;
      }
      margin-top: 64px;
    }

    .newsDetailContent {
      @include mqDF() {
        margin-top: 42px;
      }
      margin-top: 64px;
      h1,
      h2,
      h3,
      h4 {
        margin-bottom: 40px;
        font-size: 2.4rem;
        line-height: 1.6;
        color: #fff;
        letter-spacing: .04em;
        @include mqDF() {
          margin-bottom: 20px;
          font-size: 1.8rem;
        }
      }
      h5,
      h6 {
        margin-bottom: 28px;
        font-size: 2.4rem;
        line-height: 1.6;
        color: #fff;
        letter-spacing: .04em;
        @include mqDF() {
          margin-bottom: 20px;
          font-size: 1.8rem;
        }
      }

      p {
        margin-bottom: 20px;
        font-size: 1.8rem;
        line-height: 1.6;
        letter-spacing: .04em;
        @include mqDF() {
          margin-bottom: 20px;
          font-size: 1.4rem;
        }
        span {
          text-decoration: underline;
        }
        b {
          font-weight: bold;
        }
        a {
          color: $color-green;
          word-break: break-all;
          overflow-wrap: break-word;
        }
      }
      blockquote {
        text-decoration: underline;
      }
      figure {
        @include mqDF() {
          margin: 0 auto 20px;
          // width: 80%;
        }
      }

      .wp-block-media-text__content {
        @include mqDF() {
          padding: 0;
        }
      }
    }
  }
  .newsDetailOthers {
    @include mqDF() {
      margin-top: 89px;
    }
    margin-top: 132px;
    .newsPageTitle {
      @include mqDF() {
        margin-bottom: 24px;
      }
      margin-bottom: 46px;
    }
  }
}
