@charset "UTF-8";
// ==========================================================================
// Base – CSS Helpers / Extras
// ==========================================================================
// Floats
.left {
  float: left;
}
.right {
  float: right;
}

// Remove Margin / Padding
.no-margin {
  margin: 0;
}
.no-padding {
  padding: 0;
}

// Display
.block {
  display: block;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
.none,
[hidden] {
  display: none;
}

// Font Weights
.font-ultra-bold {
  font-weight: 900;
}
.font-bold {
  font-weight: 700;
}
.font-normal {
  font-weight: 500;
}
.font-light {
  font-weight: 300;
}
.font-thin {
  font-weight: 100;
}

// Font Styles
.font-normal {
  font-style: normal;
}
.capitalize {
  text-transform: capitalize;
}
.uppercase {
  text-transform: uppercase;
}

// Text Position
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.flex-center {
  justify-content: center;
}

.pre-wrap {
  white-space: pre-wrap;
}
