// @charset "UTF-8";
//  ==========================================================================
//  content
//  ==========================================================================
@use "../configs/" as *;


.l_loader {
  background: $color-black;
  height: 100%;
  @include transitionExpo(1);
  position: fixed;
  width: 100%;
  z-index: 9999;
  &.Hide {
    opacity: 0;
  }
  &.None {
    display: none;
  }
}

.l_fvMovie {
  height: 100vh;
  @include mqDF() {
    height: 100vh; /* CSS変数をサポートしていないブラウザ用のフォールバック */
    height: calc(var(--vh, 1vh) * 100);
  }
  @include transitionExpo(1);
  position: fixed;
  width: 100%;
  z-index: 9999;
  &.Hide {
    opacity: 0;
  }
  &.None {
    display: none;
  }
  #fvMovie,
  #fvMovieSP {
    left: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    &.HFit {
      svg {
        height: 100vh !important;
        @include mqDF() {
          height: auto !important;
          width: 100vw !important;
        }
        width: auto !important;
      }
    }
    &.WFit {
      svg {
        height: auto !important;
        @include mqDF() {
          height: auto !important;
          width: 100vw !important;
        }
        width: 100vw !important;
      }
    }
  }
}
.l_wrap {
  height: 100%;
  @include mqDF() {
    // overflow: hidden;
  }
  position: relative;
  width: 100%;
}

.l_contentWrap {
  box-sizing: border-box;
  @include mqDF() {
  }
  overflow: hidden;
  padding-top: 96px;
  position: relative;
  width: 100%;
  z-index: 3;
}

.l_primaryContent {
  box-sizing: border-box;
  @include mqDF() {
  }
  width: 100%;
}

.l_secondaryContent {
  background: $color-gray-a;
  box-sizing: border-box;
  @include mqDF() {
  }
  width: 100%;
}

.l_contentInner {
  @include mqDF() {
    padding-left: 5%;
    padding-right: 5%;
  }
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-left: 8.33%;
  padding-right: 8.33%;
}
.l_contentThickInner {
  @include mqDF() {
    padding-left: 5%;
    padding-right: 5%;
  }
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
  padding-left: 14%;
  padding-right: 14%;
}


.l_innerFull {
  box-sizing: border-box;
  @include mqDF() {
    padding-left: 0;
    padding-right: 0;
  }
  margin: 0 calc(50% - 50vw);
  width: 100vw;
}


.l_flexContent {
  display: flex;
  @include mqDF() {
    display: block;
  }
}
