@charset "UTF-8";
// ==========================================================================
// footer
// ==========================================================================
@use "../../configs/" as *;


.p_footerWrap {
  @include mqDF() {
    padding: 68px 0 28px;
  }
  padding: 72px 0 84px;
  position: relative;
  z-index: 3;
  .p_footerInner {
    height: 100%;
    @include mqDF() {
    }
  }

  .p_footerBody {
    display: flex;
    @include mqDF() {
      display: block;
      padding-bottom: 20px;
    }
    padding-bottom: 32px;
    position: relative;
    .p_footerInfo {
      display: flex;
      flex-direction: column;
      @include mqDF() {
        width: 100%;
      }
      width: 26%;
      .p_footerLogo {
        width: 160px;
      }
    }
    .p_footerAddress {
      color: #999;
      font-size: 1.2rem;
      @include interFont;
      letter-spacing: .04em;
      line-height: 1.8;
      margin-top: 33px;
    }
    .p_footerMailSport {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
    }
    .p_footerSport {
      bottom: 38px;
      @include mqDF() {
        bottom: unset;
        position: relative;
        right: unset;
        width: 154px;
      }
      position: absolute;
      right: 0;
    }
    .p_footerMail {
      color: #999;
      font-size: 1.2rem;
      @include interFont;
      @include mqDF() {
        margin-top: 0;
      }
      letter-spacing: .04em;
      margin-top: 26px;
    }

    .p_footerNav {
      display: flex;
      flex-direction: column;
      @include mqDF() {
        display: block;
        flex-direction: unset;
        margin-top: 26px;
        width: 100%;
      }
      position: relative;
      width: 27%;
      .p_footerMenu {
        display: flex;
        flex-wrap: wrap;
        gap: 12px 0;
        @include mqDF() {
          display: block;
          gap: unset;
          margin-top: 0;
          width: 100%;
        }
        margin-top: -6px;
        li {
          font-size: 1.4rem;
          font-weight: 500;
          @include interFont;
          @include mqDF() {
            margin-top: 18px;
            width: 100%;
            &:first-child {
              margin-top: 0;
            }
          }
          width: 50%;
        }
      }
      // .p_footerSNS {
      //   bottom: 0;
      //   left: 0;
      //   position: absolute;
      //   @include mqDF() {
      //     position: relative;
      //     bottom: unset;
      //     left: unset;
      //   }
      // }
    }
    .p_footerSNS {
      bottom: 0;
      display: flex;
      gap: 0 20px;
      @include mqDF() {
        bottom: unset;
        justify-content: center;
        justify-content: start;
        left: unset;
        margin-bottom: 0;
        margin-top: 34px;
        position: relative;
        width: 100%;
      }
      left: 0;
      position: absolute;
      .p_footerSNSItem {
        height: 22px;
        @include mqDF() {
          height: 25px;
          width: 25px;
        }
        width: 22px;
        a {
          display: block;
          height: 100%;
          width: 100%;
        }
        &.Twitter {
          background: url("../images/icon_footer_twitter.svg") no-repeat 100% center;
          background-size: 22px;
        }
        &.Note {
          background: url("../images/icon_footer_note.svg") no-repeat 100% center;
          background-size: 22px;
        }
      }
    }
  }

  .p_footerBottom {
    align-items: center;
    border-top: solid 1px rgba($color-white, $alpha: .6);
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    .p_langToggle {
      margin-top: 6px;
    }
    .p_footerCopy {
      small {
        color: $color-gray-c;
        font-size: 1.2rem;
        @include interFont;
        @include mqDF() {
          display: block;
          font-size: 1.0rem;
          text-align: center;
          width: 100%;
        }
        letter-spacing: .04em;
      }
    }
  }
}


// .p_footerWrap {
//   position: relative;
//   z-index: 3;
//   height: 86px;
//   @include mqDF() {
//     height: 87px;
//   }
//   .p_footerInner {
//     position: relative;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 100%;
//     @include mqDF() {
//       flex-direction: column-reverse;
//       flex-wrap: wrap;
//       justify-content: center;
//     }
//   }
//   small {
//     font-size: 1.2rem;
//     color: $color-gray-c;
//     letter-spacing: .04em;
//     @include interFont;
//     @include mqDF() {
//       display: block;
//       width: 100%;
//       font-size: 1.0rem;
//       text-align: center;
//     }
//   }

//   .p_footerSNS {
//     position: absolute;
//     right: 3%;
//     display: flex;
//     gap: 0 20px;
//     margin-left: auto;
//     @include mqDF() {
//       position: relative;
//       right: unset;
//       justify-content: center;
//       width: 100%;
//       margin-bottom: 14px;
//     }
//     .p_footerSNSItem {
//       width: 22px;
//       height: 22px;
//       a{
//         display: block;
//         width: 100%;
//         height: 100%;
//       }
//       &.Twitter {
//         background: url("../images/icon_footer_twitter.svg") no-repeat 100% center;
//         background-size: 22px;
//       }
//       &.Note {
//         background: url("../images/icon_footer_note.svg") no-repeat 100% center;
//         background-size: 22px;
//       }
//     }
//   }
// }
