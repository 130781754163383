@charset "UTF-8";
// ==========================================================================
// button
// ==========================================================================
@use "../../configs/" as *;
.c_formBox {
  .m_formBoxOne {
    @include mqDF() {
      margin-top: 23px;
    }
    margin-top: 34px;
    &:first-child {
      margin-top: 0;
    }
    &.Conf{
      // margin-top: 24px;
      &:first-child {
        margin-top: 0;
      }
    }
  }
}
