@charset "UTF-8";
// ==========================================================================
// card
// ==========================================================================
@use "../../configs/" as *;


.m_memberSNS {
  .m_memberSNSIcon {
    height: 36px;
    margin-bottom: 14px;
    width: 36px;
    a {
      display: block;
      height: 100%;
      width: 100%;
    }
    &.TickTock {
      background: url("../images/icon_ticktock.png") no-repeat center;
      background-size: 36px;
    }
    &.Instagram {
      background: url("../images/icon_instagram.png") no-repeat center;
      background-size: 36px;
    }
    &.Note {
      background: url("../images/icon_note.png") no-repeat center;
      background-size: 36px;
    }
    &.Twitter {
      background: url("../images/icon_twitter.png") no-repeat center;
      background-size: 36px;
    }
    &.Heart {
      // background:url('../images/icon_heart.png') no-repeat center;
      background-size: 36px;
      margin-top: 10px;
      .HeartInner {
        transform: scale(2.2);
      }
    }
  }
}
.m_memberRole {
  color: $color-gray-d;
  display: block;
  font-size: 1.2rem;
}
.m_memberInfo {
  dt {
    font-size: 2.0rem;
  }
  dd {
    font-size: 1.2rem;
  }
}




.m_projectCardCaption {
  dt {
    color: $color-green;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    @include interFont;
    @include mqDF() {
      font-size: 1.2rem;
    }
    letter-spacing: .04em;
    text-transform: uppercase;
  }
  dd {
    color: $color-white;
    font-size: 2.0rem;
    font-style: normal;
    font-weight: 700;
    @include interFont;
    @include mqDF() {
      font-size: 2.4rem;
      margin-top: 2px;
    }
    letter-spacing: .04em;
  }
}
.m_projectCardView {
  color: $color-white;
  letter-spacing: .04em;
  margin-top: 4px;
  position: relative;
  width: 80px;
  a,
  p {
    background: url("../images/icon_view_arrow.svg") no-repeat 100% center;
    background-size: 14px;
    display: block;
    font-size: 1.4rem;
    @include interFont;
    @include mqDF() {
      font-size: 1.4rem;
    }
    line-height: 28px;
  }

  &::after {
    background: $color-gray-c;
    bottom: -1px;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
  &::before {
    background: $color-green;
    bottom: -1px;
    content: "";
    height: 1px;
    @include transitionExpo(.5);
    left: 0;
    position: absolute;
    transform: scale(0, 1);
    transform-origin: left center;
    width: 100%;
    z-index: 2;
  }

  &:hover {
    color: $color-green;
    @include mqDF() {
      color: $color-white;
      padding-left: 0;
    }
    padding-left: 1%;
    a,
    button {
      background: url("../images/icon_view_arrow_g.svg") no-repeat 100% center;
      background-size: 9px;
      @include mqDF() {
        background: url("../images/icon_view_arrow.svg") no-repeat 100% center;
        background-size: 9px;
      }
    }
    &::before {
      @include mqDF() {
        transform: scale(0, 1);
      }
      transform: scale(1, 1);
    }
  }
}
